import React, { Component } from "react";
import { PropTypes } from "prop-types";
import MetaTags from "react-meta-tags";
import { jwtDecode } from "jwt-decode";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faInfoCircle,
  faCheck,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import { faSquare, faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import { Button } from "@nvidia/kaizen-ui";
//import '@nvidia/kaizen-ui/kaizen-ui.css'

import * as queryString from "query-string";
import axios from "axios";
import ReactAI from "react-appinsights";

import AppHeader from "./components/presentational/AppHeader";
import InfoBlock from "./components/presentational/InfoBlock";

import ConfirmationDialog from "./components/util/ModalPop";

import Entitlement from "./components/presentational/Entitlement";
import PrimaryContact from "./components/presentational/PrimaryContact";
import CompanyInfo from "./components/presentational/CompanyInfo";
import PrimaryContactDetails from "./components/presentational/PrimaryContactDetails";
import Environment from "./components/presentational/Environment";
import ContainerRegistry from "./components/presentational/ContainerRegistry";
import Footer from "./components/presentational/Footer";
import Error from "./components/presentational/Error";
import MessageCenter from "./components/presentational/MessageCenter";
import ActionBar from "./components/presentational/ActionBar";
import Start from "./components/presentational/Start";

import ConfigManager from "./config/configManager";

import * as ApiMethods from "./constants/apiMethods";
import {GET_NGCCreate} from "./constants/apiMethods";
import * as ErrorCodes from "./constants/errorCode";

import "./styles/App.scss";
import { isArray } from "util";
import { AsYouType } from "libphonenumber-js";

import WarrantyClaim from "./warrantyClaims"
const SpecialComponent = () => <h1>This is the Special Component</h1>;

// Main routing structure
const Main = () => {
  return (
    <Router>
      <Routes>
        {/* Home route */}
        <Route path="/" element={<App />} />
        
        {/* Additional route */}
        <Route path="/warrantyclaim" element={<WarrantyClaim />} />
        
        {/* Optional: Catch-all for undefined routes */}
        <Route path="*" element={<h1>404 - Not Found</h1>} />
      </Routes>
    </Router>
  );
};
export default ReactAI.withTracking(Main);

class App extends Component {
  constructor(props) {
    super(props);

    this.config = ConfigManager();
    this.defaultStateOption = {
      id: 0,
      code: "",
      stateName: "Select an Option",
    };
    this.defaultOption = { key: 0, value: "Select an Option" };
    this.defaultCountryOption = {
      id: 0,
      code: "",
      countryName: "Select a Location",
    };

    library.add([faInfoCircle, faCheckSquare, faSquare, faCheck, faSpinner]);

    const parsed = queryString.parse(window.location.search);
    let qsOptions = {};
    for (let key in parsed) {
      qsOptions[key.toUpperCase()] = parsed[key];
    }

    if (props.licenseType !== undefined) {
      qsOptions.LICTYPE = props.licenseType;
    }

    if (props.productFamily !== undefined) {
      qsOptions.PRODUCTFAMILY = props.productFamily;
    }

    if (props.pakId !== undefined) {
      qsOptions.PAKID = props.pakId;
    }

    this.state = {
      PakIdProvided: qsOptions.PAKID !== undefined,
      PakId: qsOptions.PAKID ? qsOptions.PAKID : "",
      Serial: "",
      ShowCompanySection: true,
      LicenseType: qsOptions.LICTYPE,
      ProductFamily: qsOptions.PRODUCTFAMILY,
      IsFleetingPF: (qsOptions.PRODUCTFAMILY && qsOptions.PRODUCTFAMILY.toUpperCase() === "FLEETCOMMAND" || (qsOptions.PRODUCTFAMILY && qsOptions.PRODUCTFAMILY.toUpperCase() === "NVAIENTERPRISE")) ? true : false,
      IsPartnerPF: (qsOptions.PRODUCTFAMILY && qsOptions.PRODUCTFAMILY.toUpperCase() === "PARTNEROMNIVERSE") ? true : false,
      IsPartnerPFOmn: (qsOptions.PRODUCTFAMILY && qsOptions.PRODUCTFAMILY.toUpperCase() === "OMNIVERSE") ? true : false,
      IsPartnerURLPresent:qsOptions.PARTNER && qsOptions.PARTNER ? true: false,
      PartnerUrlParam:
        qsOptions.PARTNER === undefined
          ? ""
          : qsOptions.PARTNER,
              
      LicenseTypeName: qsOptions.LICTYPE,
      isBoxx:false,
      ProductFamilyName: qsOptions.PRODUCTFAMILY,
      LicenseTypeID: 0,
      ProductFamilyID: 0,
      ProductFamilyCategoryID: 0,
      LicenseTypeCategoryID: 0,
      RWAGroupID: 0,
      IsRWA01: false,
      IsRWA02: false,
      IsRWA04: false,
      IsRWA03: false,
      IsEval: false,
      IsVgpu: false,
      IsCommercial: false,

      IsGrid: false,
      IsDgx: false,
      AllowPakIdEdit: qsOptions.PAKID === undefined,
      AllowSerialEdit: qsOptions.PAKID === undefined,
      EmailAddress: qsOptions.EMAILID === undefined ? "" : qsOptions.EMAILID,
      ConfirmEmailAddress: "",
      FirstName: qsOptions.FIRSTNAME === undefined ? "" : qsOptions.FIRSTNAME,
      LastName: qsOptions.LASTNAME === undefined ? "" : qsOptions.LASTNAME,
      lockEmail: qsOptions.EMAILID !== undefined,
      lockFirstName: qsOptions.FIRSTNAME !== undefined,
      lockLastName: qsOptions.LASTNAME !== undefined,
      ClaimingEntitlementAs: 0,
      OrgData: {
        CompanyId: "",
        CompanyName: "",
        CompanyCountry: 0,
        CompanyState: 0,
        CompanyIndustry: 0,
        CompanyStreet1: "",
        CompanyStreet2: "",
        CompanyCity: "",
        CompanyZip: "",
        CompanyJobRole: "",
        CompanyPhone: "",
      },
      SelectedCountryCode: "",
      IsOrgLock: false,
      ContainerNeeded: false,
      ContainerAdmin: 1,
      ContainerAltEmail: "",
      ContainerAltFirstName: "",
      ContainerAltLastName: "",
      ContainerAltPhone: "",
      ContainerShortCompanyName: "",
      UseAltAddress: false,
      AltStreet1: "",
      AltStreet2: "",
      AltCity: "",
      AltCountry: 0,
      AltState: 0,
      AltZip: "",
      AltStates: [this.defaultStateOption],
      SendNews: false,
      Countries: [this.defaultCountryOption],
      States: [this.defaultStateOption],
      ClaimingEntitlementAsOptions: [{ key: 0, value: "Loading Data..." }],
      IndustryOptions: [{ key: 0, value: "Loading Industries..." }],
      JobRoleOptions: [{ key: 0, value: "Loading Job Roles..." }],
      ContainerAdminOptions: [{ key: 0, value: "Loading Data..." }],
      CertifiedServerOptions: [{ key: 0, value: "Loading Servers..." }],
      HypervisorOptions: [{ key: 0, value: "Loading Options..." }],
      VdiSeatsOptions: [{ key: 0, value: "Loading Seats..." }],
      GpuOptions: [{ key: 0, value: "Loading GPUs..." }],
      VdiRemotingOptions: [{ key: 0, value: "Loading Options..." }],
      PrimaryApplicationOptions: [{ key: 0, value: "Loading Applications..." }],
      NvidiaGPUDeployedOptions: [{ key: 0, value: "Loading Options..." }],
      DeploymentOptions: [{ key: 0, value: "Loading Options..." }],
      UseCaseOptions: [{ key: 0, value: "Loading Options..." }],
      EstimatedUsersOptions: [{ key: 0, value: "Loading Options..." }],
      CompatibleHardwareOptions: [{ key: 0, value: "Loading Options..." }],
      VMWareOptions: [{ key: 0, value: "Loading Options..." }],
      PartnerDeploymentOptions: [{ key: 0, value: "Loading Options..." }],
      PartnerNvidiaGPUOptions: [{ key: 0, value: "Loading Options..." }],
      PartnerCertifiedSystemOptions: [{ key: 0, value: "Loading Options..." }],
      BoxxNvidiaGPUOptions: [{ key: 0, value: "Loading Options..." }],
      BoxxDeploymentTypeOptions: [{ key: 0, value: "Loading Options..." }],
      BoxxDeploymentOptions: [{ key: 0, value: "Loading Options..." }],
      BoxxCertifiedSystemOptions: [{ key: 0, value: "Loading Options..." }],
      NETQOSOptions: [{ key: 0, value: "Loading Options..." }],
      IsNetQSaas:false,
      NetQFirstName:'',
      NetQLastName:'',
      NetQEmail:'',
      IsNetQSameUser:false,
      ufmDeploymentMethodID:0,
      ufmPlatformID:0,
      ufmNumberOfNodesID:0,
      BoxxNvidiaGPUId:0,
      BoxxDeploymentTypeId:0,
      BoxxDeploymentId:0,
      BoxxCertifiedSystemId:0,
      PartnerDeploymentId:0,
      PartnerNvidiaGPUId:0,
      PartnerCertifiedSystemId:0,
      CertifiedServer: 0,
      IsOtherSelected:false,
      GPUDeployed:0,
      OtherApplicationsVal:'',
      DeploymentOp:0,
      UseCaseValue:0,
      EstimatedNoOfUsers:0,
      VdiHypervisor: 0,
      CompatibleHardware:0,
      NetworkOperatingSystem:0,
      OtherNetworkElements:'',
      NETQSaasOption:false,
      PremiseName:'',
      NumberOfSwicthes:'',
      VMWareValue:0,
      VdiSeats: 0,
      Gpu: 0,
      VdiRemoting: 0,
      PrimaryApplication: 0,
      PrimaryApplicationMul: [],
      InvalidFields: [],
      Messages: [],
      MessageIcon: true,
      AllowSubmit: true,
      RegistrationSubmitted: false,
      RegistrationSuccessMessage: "",
      UserInputNeeded: false,
      UserInput: "",
      IsNotFound: false,
      IsLoaded: false,
      IsTokenPresent: qsOptions.ACTIVATIONTOKEN !== undefined,
      IsTokenValid: false,
      IsLGSEmailValid: false,
      LGSEmailLoad: false,
      ActivationToken:
        qsOptions.ACTIVATIONTOKEN === undefined
          ? ""
          : qsOptions.ACTIVATIONTOKEN,
      IsLGSRender: false,
      IsEvalMultipleAccount: false,
      NCID: qsOptions.NCID === undefined ? "" : qsOptions.NCID,
      NVID: qsOptions.NVID === undefined ? "" : qsOptions.NVID,
      EmailValidationLoading:false,
      EmailValidationCheck:false,
      EmailValidationWrong:false,
      ufmDeploymentMethodOptions:[],
      ufmNumberOfNodesOptions:[],
      ufmPlatformOptions:[],
      ufmPlatformOptionsModified:[{ key: 0, value: "Select an Option" }],

      nvaiGPUOptions:[],
      nvaiInfraOptions:[],
      nvaiSizeOptions:[],
      nvaiVirtualOptions:[],
      nvaiWorkloadsOptions:[],
      stmxGPUOptions:[],
      stmxModelOptions:[],
      stmxNosOptions:[],
      stmxSwitchOptions:[],
      stmxVendorOptions:[],

      nvaiGPUID:0,
      nvaiInfraID:0,
      nvaiDeploymentID:0,
      nvaiWorkLoadsID:0,
      nvaiProjectSizeID:0,

      stmxGPUID:0,
      stmxVendorID:0,
      stmxSwitchModelID:0,
      stmxNosSwitchEvalID:0,
      stmxSwitchNOSID:0,
      stmxAIAPPName:'',
      stmxNvidiaContact:'',
      ngc_Create:false,
      pak_Completed : true,
      nca_nan:'',
      nca_nid:'',
      starfleet_id : '',
      idp_id : '',
      showPicker : false,
      primaryowneremail : ''
    };
    this.validateLGSActivationToken();
    this.getLookupData();
    this.getRWAMappingData();
    this.getEvalNGCCreateDataByFamily();
    this.handleCallback(); 
  }

  handleNCAData = (e) => {
      if(this.state.ngc_Create)
      {
        var nan = localStorage.getItem("nan");
        var nid =  localStorage.getItem("nid");
        var sub = localStorage.getItem("sub");
        var idp_id = localStorage.getItem("idp_id");
        var primaryowneremail = localStorage.getItem("primaryowneremail");
        this.setState({ nca_nan: nan, nca_nid : nid, starfleet_id:sub, idp_id:idp_id , primaryowneremail : primaryowneremail});
      }
      if(this.state.pak_Completed == false)
      {
        var nca_email = localStorage.getItem("email");
        var disableEmail = false;
        if(nca_email !="" && nca_email != undefined ){
            disableEmail = true;
        }
        this.setState({EmailAddress : nca_email, ConfirmEmailAddress : nca_email, lockEmail:disableEmail});
      }  
      this.handlePicker();       
  };

  handlePicker = (e) =>{
    if(this.state.ngc_Create && !this.state.nca_nan && !this.state.pak_Completed){
      this.setState({showPicker : true})    
    }  
  }

  handlePakIdChange = (e) => {
    const val = e.target.value;
    this.setState({ AllowSerialEdit: val === "", PakId: val });
    this.validateEntitlement();
  };
  handleSerialChange = (e) => {
    const val = e.target.value;
    this.setState({ AllowPakIdEdit: val === "", Serial: val });
    this.validateEntitlement();
  };
  handleEmailChange = (e) => {
    const val = e.target.value;
    this.setState({ EmailAddress: val });
  };
  handleNetQFirstNameChange = (e) => {
    const val = e.target.value;
    this.setState({ NetQFirstName: val });
  };
  handleNetQLastNameChange = (e) => {
    const val = e.target.value;
    this.setState({ NetQLastName: val });
  };
  handleNetQEmailChange = (e) => {
    const val = e.target.value;
    this.setState({ NetQEmail: val });
  };
  
  handleEmailChangeConfirm = (e) => {
    const val = e.target.value;
    this.setState({ ConfirmEmailAddress: val });
  };
  handleEmailConfirmPaste = (e) => {
    e.preventDefault();
  };
  handleEmailBlur = (e) => {
    this.validateLGSEmail();
    this.validateEmailAddressThroughAPI();
  };

  handleFirstNameChange = (e) => {
    const val = e.target.value;
    this.setState({ FirstName: val });

    // this.setState({ FirstName: val });
  };
  handleOtherApplicationChange = (e) => {
    const val = e.target.value;
    this.setState({ OtherApplicationsVal: val });

    // this.setState({ FirstName: val });
  };
  handleOtherNetworkElementsChange = (e) => {
    const val = e.target.value;
    this.setState({ OtherNetworkElements: val });

    
  };
  handlePremiseNameChange = (e) => {
    const val = e.target.value;
    this.setState({ PremiseName: val });

 
  };
  handleNumberOfSwicthesChange = (e) => {
    const val = e.target.value;
    this.setState({ NumberOfSwicthes: val });

 
  };
  handleCancelClickPop = (e) => {
    if(this.state.ProductFamily.toUpperCase()==='OMNIVERSE')
    {
      if(this.state.PartnerUrlParam&&(this.state.PartnerUrlParam.toUpperCase()==="BOXX"||this.state.PartnerUrlParam.toUpperCase()==="LENOVO"))
      {
        let msg=ErrorCodes.renderPartnerCancelMessage(
        
          );
          this.setState({
            Messages: [msg],
            AllowSubmit: false,
            UserInputNeeded: false,
            RegistrationSubmitted: true,
            RegistrationSuccessMessage: msg,
          });
      }
      else{
        window.location.href='https://www.nvidia.com/en-us/omniverse/enterprise/free-trial/#faqs';
      }
      
    }
    else if(this.state.ProductFamily.toUpperCase()==='SPECTRUM-X')
    {
      let msg=ErrorCodes.renderSpectrumxCancelMessage(
        
      );
      this.setState({
        Messages: [msg],
        AllowSubmit: false,
        UserInputNeeded: false,
        RegistrationSubmitted: true,
        RegistrationSuccessMessage: msg,
      });
    }
    else if(this.state.ProductFamily.toUpperCase()==='NVAIENTERPRISE')
    {
      let msg=ErrorCodes.renderNVAIEnterCancelMessage(
        
      );
      this.setState({
        Messages: [msg],
        AllowSubmit: false,
        UserInputNeeded: false,
        RegistrationSubmitted: true,
        RegistrationSuccessMessage: msg,
      });
    }
    
    else if(this.state.ProductFamily.toUpperCase()==='VGPU')
    {
      let msg=ErrorCodes.renderVGPUCancelMessage(
        
      );
      this.setState({
        Messages: [msg],
        AllowSubmit: false,
        UserInputNeeded: false,
        RegistrationSubmitted: true,
        RegistrationSuccessMessage: msg,
      });
    }
    else if(this.state.ProductFamily.toUpperCase()==='NVAIE')
    {
      let msg=ErrorCodes.renderNVAIECancelMessage(
        
      );
      this.setState({
        Messages: [msg],
        AllowSubmit: false,
        UserInputNeeded: false,
        RegistrationSubmitted: true,
        RegistrationSuccessMessage: msg,
      });
    }
    else if(this.state.ProductFamily.toUpperCase()==='PARTNEROMNIVERSE')
    {
      let msg=ErrorCodes.renderPartnerCancelMessage(
        
      );
      this.setState({
        Messages: [msg],
        AllowSubmit: false,
        UserInputNeeded: false,
        RegistrationSubmitted: true,
        RegistrationSuccessMessage: msg,
      });
    }
    else if(this.state.ProductFamily.toUpperCase()==='NETQ')
    {
      let msg=ErrorCodes.renderNETQCancelMessage(
        
      );
      this.setState({
        Messages: [msg],
        AllowSubmit: false,
        UserInputNeeded: false,
        RegistrationSubmitted: true,
        RegistrationSuccessMessage: msg,
      });
    }
    else if(this.state.ProductFamily.toUpperCase()==='NETQSAAS')
    {
      let msg=ErrorCodes.renderNETQSAASCancelMessage(
        
      );
      this.setState({
        Messages: [msg],
        AllowSubmit: false,
        UserInputNeeded: false,
        RegistrationSubmitted: true,
        RegistrationSuccessMessage: msg,
      });
    }
    else if(this.state.ProductFamily.toUpperCase()==='UFM')
    {
      let msg=ErrorCodes.renderUFMCancelMessage(
        
      );
      this.setState({
        Messages: [msg],
        AllowSubmit: false,
        UserInputNeeded: false,
        RegistrationSubmitted: true,
        RegistrationSuccessMessage: msg,
      });
    }
    
    else 
    {
      let msg=ErrorCodes.renderVGPUCancelMessage(
        
      );
      this.setState({
        Messages: [msg],
        AllowSubmit: false,
        UserInputNeeded: false,
        RegistrationSubmitted: true,
        RegistrationSuccessMessage: msg,
      });
    }
    
    
  };
  

  handleNVAIGPUChange = (e) => {
    this.setState({ nvaiGPUID: parseInt(e.target.value) });
  };
  handleNVAIInfraChange = (e) => {
    this.setState({ nvaiInfraID: parseInt(e.target.value) });
  };
  handleNVAIDeploymentChange = (e) => {
    this.setState({ nvaiDeploymentID: parseInt(e.target.value) });
  };
  handleNVAIWorkLoadsChange = (e) => {
    this.setState({ nvaiWorkLoadsID: parseInt(e.target.value) });
  };
  handleNVAIProjectSizeChange = (e) => {
    this.setState({ nvaiProjectSizeID: parseInt(e.target.value) });
  };
//stmx
  handleNSTMXGPUChange = (e) => {
    this.setState({ stmxGPUID: parseInt(e.target.value) });
  };
  handleSTMXVendorChange = (e) => {
    this.setState({ stmxVendorID: parseInt(e.target.value) });
  };
  handleSTMXSwitchModelChange = (e) => {
    this.setState({ stmxSwitchModelID: parseInt(e.target.value) });
  };
  handleSTMXNosSwitchEvalChange = (e) => {
    this.setState({ stmxNosSwitchEvalID: parseInt(e.target.value) });
  };
  handleSTMXSwitchNOSChange = (e) => {
    this.setState({ stmxSwitchNOSID: parseInt(e.target.value) });
  };


  handleSTMXAIAppChange = (e) => {
    const val = e.target.value;
    this.setState({  stmxAIAPPName: val });
  };

  handleSTMXNvidiaContactChange = (e) => {
    const val = e.target.value;
    this.setState({  stmxNvidiaContact: val });
  };













  handleLastNameChange = (e) => {
    const val = e.target.value;
    this.setState({ LastName: val });
  };
  handleClaimingEntitlementAsChange = (e) => {
    const val = parseInt(e.target.value);
    this.setState({ ClaimingEntitlementAs: val });
  };
  handleCompanyNameChange = (e) => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.CompanyName = val;
    this.setState({ OrgData: orgData });
  };
  handleCompanyCountryChange = (e) => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.CompanyCountry = parseInt(val);

    const selected = this.state.Countries.filter(
      (country) => parseInt(country.id) === parseInt(val)
    );

    const countryCode = selected[0].code;
    this.setState({ OrgData: orgData, SelectedCountryCode: countryCode });
    this.getStatesByCountry(val);
  };
  handleCompanyStateChange = (e) => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.CompanyState = parseInt(val);
    this.setState({ OrgData: orgData });
  };
  handleCompanyIndustryChange = (e) => {
    const val = parseInt(e.target.value);
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.CompanyIndustry = parseInt(val);
    this.setState({ OrgData: orgData });
  };
  handleDetailsStateChange = (e) => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.CompanyState = parseInt(val);
    this.setState({ OrgData: orgData });
  };
  handleDetailsCountryChange = (e) => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.CompanyCountry = parseInt(val);

    this.setState({ OrgData: orgData });
  };
  handleDetailsJobRoleChange = (e) => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.CompanyJobRole = val;
    this.setState({ OrgData: orgData });
  };
  handleDetailsStreet1Change = (e) => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.CompanyStreet1 = val;
    this.setState({ OrgData: orgData });
  };
  handleDetailsStreet2Change = (e) => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.CompanyStreet2 = val;
    this.setState({ OrgData: orgData });
  };
  handleDetailsCityChange = (e) => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.CompanyCity = val;
    this.setState({ OrgData: orgData });
  };
  handleDetailsZipChange = (e) => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.CompanyZip = val;
    this.setState({ OrgData: orgData });
  };
  handleDetailsPhoneChange = (e) => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    const countryCode = this.state.SelectedCountryCode;

    orgData.CompanyPhone = new AsYouType(countryCode).input(val);
    this.setState({ OrgData: orgData });
  };
  handleAdminChange = (e) => {
    this.setState({ ContainerAdmin: parseInt(e.target.value) });
  };
  handleAltAddressChange = (e) => {
    this.setState({ UseAltAddress: !e.target.checked });
  };
  handleAltStreet1Change = (e) => {
    this.setState({ AltStreet1: e.target.value });
  };
  handlePartnerValueChange = (e) => {
    this.setState({ PartnerUrlParam: e.target.value });
  };
  handleAltStreet2Change = (e) => {
    this.setState({ AltStreet2: e.target.value });
  };
  handleAltCityChange = (e) => {
    this.setState({ AltCity: e.target.value });
  };
  handleAltCountryChange = (e) => {
    const val = parseInt(e.target.value);
    const selected = this.state.Countries.filter(
      (country) => parseInt(country.id) === val
    );

    const countryCode = selected[0].code;

    this.setState({ AltCountry: val, SelectedCountryCode: countryCode });
    this.getStatesByCountry(val, true);
  };
  handleAltStateChange = (e) => {
    this.setState({ AltState: parseInt(e.target.value) });
  };
  handleAltZipChange = (e) => {
    this.setState({ AltZip: e.target.value });
  };
  handleShortCompanyNameChange = (e) => {
    this.setState({ ContainerShortCompanyName: e.target.value });
  };
  handleContainerEmailChange = (e) => {
    this.setState({ ContainerAltEmail: e.target.value });
  };
  handleContainerFirstNameChange = (e) => {
    this.setState({ ContainerAltFirstName: e.target.value });
  };
  handleContainerLastNameChange = (e) => {
    this.setState({ ContainerAltLastName: e.target.value });
  };
  handleContainerPhoneChange = (e) => {
    this.setState({ ContainerAltPhone: e.target.value });
  };
  handleSendNewsChange = (e) => {
    this.setState({ SendNews: e.target.checked });
  };
  handleNetqSaasChange = (e) => {
    this.setState({ IsNetQSaas: e.target.checked });
  };
  handleNetqSameUserChange = (e) => {
    this.setState({ IsNetQSameUser: e.target.checked });
   
  };
  handleUFMNumberOfNodesChange = (e) => {
    this.setState({ ufmNumberOfNodesID: e.target.value });
   
  };
  handleUFMPlatformChange = (e) => {
    this.setState({ ufmPlatformID: e.target.value });
   
  };
  
  handleUFMDeploymentMethodChange = (e) => {
    this.setState({ ufmDeploymentMethodID: e.target.value });
    var platformArray=this.state.ufmPlatformOptions.map(x => x);
    
    
   if(e.target.value && e.target.value==1)
   {  
     var iidex=platformArray.findIndex(item => item.value === "None");
    
    if(iidex>=0)
    {
     platformArray.splice(iidex, 1);
      
      
      this.setState({ ufmPlatformOptionsModified:platformArray });
    }

   }
   else if(e.target.value && e.target.value==2){
    var iidex=platformArray.findIndex(item => item.value === "None");    
    if(iidex>=0)
    {
      platformArray.splice(2, platformArray.length-1);
      
      
      this.setState({ ufmPlatformOptionsModified:platformArray });
    }
   }
   else{
    platformArray.length = 1;
    this.setState({ ufmPlatformOptionsModified:platformArray });
   }
   this.setState({ ufmPlatformOptionsModified:platformArray });
  };
  
  handleServerChange = (e) => {
    this.setState({ CertifiedServer: parseInt(e.target.value) });
  };
  handleHypervisorChange = (e) => {
    this.setState({ VdiHypervisor: parseInt(e.target.value) });
  };
  handleSeatsChange = (e) => {
    this.setState({ VdiSeats: parseInt(e.target.value) });
  };
  handleGpuChange = (e) => {
    this.setState({ Gpu: parseInt(e.target.value) });
  };
  handleRemotingChange = (e) => {
    this.setState({ VdiRemoting: parseInt(e.target.value) });
  };
  handleCompatibleHardwareChange = (e) => {
    this.setState({ CompatibleHardware: parseInt(e.target.value) });
  };
  handleNetworkOperatingSystemChange = (e) => {
    this.setState({ NetworkOperatingSystem: parseInt(e.target.value) });
  };
  handleVMWareChange = (e) => {
    this.setState({ VMWareValue: parseInt(e.target.value) });
  };
  
  handlePartnerDeploymentChange = (e) => {
    this.setState({ PartnerDeploymentId: parseInt(e.target.value) });
  };
  

  handleBoxxDeploymentChange = (e) => {
    this.setState({ BoxxDeploymentId: parseInt(e.target.value) });
  };
  handleBoxxDeploymentTypeChange = (e) => {
    this.setState({ BoxxDeploymentTypeId: parseInt(e.target.value) });
  };
  handleBoxNvidiaGPUChange = (e) => {
    this.setState({ BoxxNvidiaGPUId: parseInt(e.target.value) });
  };
  handleBoxxCertifiedServerChange = (e) => {
    this.setState({ BoxxCertifiedSystemId: parseInt(e.target.value) });
  };




  handlePartnerCertifiedSystemChange = (e) => {
    this.setState({ PartnerCertifiedSystemId: parseInt(e.target.value) });
  };
  handlePartnerNvidiaGPUChange = (e) => {
    this.setState({ PartnerNvidiaGPUId: parseInt(e.target.value) });
  };
  
  handlePrimaryAppChange = (e) => {
    let isOtherSel=true;
    
    
    this.setState({ IsOtherSelected: isOtherSel });
    this.setState({ PrimaryApplicationMul:   e.map(a => a.value) }) 
    
  };
  handleGPUDeployed = (e) => {
   
    
    this.setState({ GPUDeployed:   parseInt(e.target.value) }) 
    
  };
  handleDeploymentOptions = (e) => {
    
    
    this.setState({ DeploymentOp:   parseInt(e.target.value) }) 
    
  };
  handleUseCase = (e) => {
    
    
    this.setState({ UseCaseValue:   parseInt(e.target.value) }) 
    
  };
  handleEstimatedUsers = (e) => {
    
    
    this.setState({ EstimatedNoOfUsers:   parseInt(e.target.value) }) 
    
  };

  handlePakIdBlur = (e) => {
    let val = e.target.value;
    if (val !== "") {
      this.validateEntitlement();
    }
  };
  handleSerialBlur = (e) => {
    let val = e.target.value;
    if (val !== "") {
      this.validateEntitlement();
    }
  };

  handleUserInput = (e) => {
    this.setState({ UserInput: e });
    this.validateFields(e);
  };
  handleYesClick = (e) => {
    this.handleUserInput("Yes");
  };
  handleNoClick = (e) => {
    // this.handleUserInput("No");
    window.location.reload();
  };

  handleSubmitClick = (e) => {
    this.validateFields();
  };

  validateFields(userInput) {
    this.setState({ AllowSubmit: false });
    const validateNameRegex = /^[a-zA-Z'\- ]*$/;
    // const { IsDgx } = this.state;
    const { IsRWA02, IsRWA03,IsRWA04 } = this.state;

    let valid = true;

    let issues = [];

 if (IsRWA03 && this.state.ProductFamily.toUpperCase()==="OMNIVERSE") {
  if(this.state.PartnerUrlParam&& this.state.PartnerUrlParam.toUpperCase()==="BOXX")
  {

    const {
        BoxxNvidiaGPUId,
      BoxxDeploymentTypeId,
     BoxxDeploymentId,
      BoxxCertifiedSystemId,
     
     
      
      
    } = this.state;
    const { CompanyName } = this.state.OrgData;
   
    if (BoxxNvidiaGPUId === 0) {
      valid = false;
      issues.push("Please select a nvidia GPU option.");
    }
    if (BoxxDeploymentTypeId === 0) {
      valid = false;
      issues.push("Please select a deployment type option.");
    }
  /*   if (BoxxDeploymentId === 0) {
      valid = false;
      issues.push("Please select a deployment option.");
    } */
   
    if (BoxxCertifiedSystemId === 0) {
      valid = false;
      issues.push("Please select a certified system option.");
    }
   
    
    if (CompanyName === "" || !CompanyName) {
      valid = false;
      issues.push("Please provide your company name.");
    }
   
    if (CompanyName && CompanyName.indexOf('"') >= 0) {
      valid = false;
      issues.push("Company name cannot contain \".");
    }
    //to include change for restricting [ character]
    if (CompanyName && CompanyName.indexOf('[') >= 0) {
      valid = false;
      issues.push("Company name cannot contain [.");
    }
    if (CompanyName && CompanyName.indexOf('\\') >= 0) {
      valid = false;
      issues.push("Company name cannot contain \\.");
    }
  }
  else
  {
      const {
        GPUDeployed,
        DeploymentOp,
        UseCaseValue,
        EstimatedNoOfUsers,
        OtherApplicationsVal,
        PrimaryApplicationMul,
        
      } = this.state;
      const { CompanyName } = this.state.OrgData;
      if (GPUDeployed === 0) {
        valid = false;
        issues.push("Please select a nvidia gpu deployed option.");
      }
      if (DeploymentOp === 0) {
        valid = false;
        issues.push("Please select a deployment option.");
      }
      if (UseCaseValue === 0) {
        valid = false;
        issues.push("Please select a usecase option.");
      }
      if (EstimatedNoOfUsers === 0) {
        valid = false;
        issues.push("Please select a estimated number of users option.");
      }
      if (PrimaryApplicationMul.length === 0) {
        valid = false;
        issues.push("Please select a primary application option.");
      }
      // if (this.state.IsOtherSelected&&!OtherApplicationsVal) {
      //   valid = false;
      //   issues.push("Please enter other application name.");
      // }
      if (CompanyName === "" || !CompanyName) {
        valid = false;
        issues.push("Please provide your company name.");
      }
     
      if (CompanyName && CompanyName.indexOf('"') >= 0) {
        valid = false;
        issues.push("Company name cannot contain \".");
      }
      //to include change for restricting [ character]
      if (CompanyName && CompanyName.indexOf('[') >= 0) {
        valid = false;
        issues.push("Company name cannot contain [.");
      }
      if (CompanyName && CompanyName.indexOf('\\') >= 0) {
        valid = false;
        issues.push("Company name cannot contain \\.");
      }
  
    }
  
  
  
    }
   else if (IsRWA03 && this.state.ProductFamily.toUpperCase()==="NVAIE") {
  
      const {
        CompatibleHardware,
      VMWareValue,      
        
        EstimatedNoOfUsers,
        
        
      } = this.state;
      const { CompanyName } = this.state.OrgData;
      if (CompatibleHardware === 0) {
        valid = false;
        issues.push("Please select a Compatible Hardware option.");
      }
      if (VMWareValue === 0) {
        valid = false;
        issues.push("Please select a VMware vSphere 7 U2 Enterprise Edition or later option.");
      }
     
      if (EstimatedNoOfUsers === 0) {
        valid = false;
        issues.push("Please select a estimated number of users option.");
      }
     
      
      if (CompanyName === "" || !CompanyName) {
        valid = false;
        issues.push("Please provide your company name.");
      }
     
      if (CompanyName && CompanyName.indexOf('"') >= 0) {
        valid = false;
        issues.push("Company name cannot contain \".");
      }
      //to include change for restricting [ character]
      if (CompanyName && CompanyName.indexOf('[') >= 0) {
        valid = false;
        issues.push("Company name cannot contain [.");
      }
      if (CompanyName && CompanyName.indexOf('\\') >= 0) {
        valid = false;
        issues.push("Company name cannot contain \\.");
      }
    }
    else if (IsRWA03 && this.state.ProductFamily.toUpperCase()==="PARTNEROMNIVERSE") {
     
      
  
      const {
        PartnerDeploymentId,
        PartnerNvidiaGPUId,
        PartnerCertifiedSystemId,
       
        
        
      } = this.state;
      const { CompanyName } = this.state.OrgData;
      if (PartnerDeploymentId === 0) {
        valid = false;
        issues.push("Please select a deployment option.");
      }
      if (PartnerNvidiaGPUId === 0) {
        valid = false;
        issues.push("Please select a nvidia gpu option.");
      }
     
      if (PartnerCertifiedSystemId === 0) {
        valid = false;
        issues.push("Please select a certified system option.");
      }
     
      
      if (CompanyName === "" || !CompanyName) {
        valid = false;
        issues.push("Please provide your company name.");
      }
     
      if (CompanyName && CompanyName.indexOf('"') >= 0) {
        valid = false;
        issues.push("Company name cannot contain \".");
      }
      //to include change for restricting [ character]
      if (CompanyName && CompanyName.indexOf('[') >= 0) {
        valid = false;
        issues.push("Company name cannot contain [.");
      }
      if (CompanyName && CompanyName.indexOf('\\') >= 0) {
        valid = false;
        issues.push("Company name cannot contain \\.");
      }
    
   }
   else if (IsRWA04 && this.state.ProductFamily.toUpperCase()==="NVAIENTERPRISE") {
     
    const {

      
      NvaiworkloadsId ,
      NvaiprojectSizeId,
      nvaiDeploymentID ,
      nvaiInfraID,
      nvaiGPUID,


    } = this.state;
    if(this.state.PartnerUrlParam&& this.state.PartnerUrlParam.toUpperCase()==="SNOWFLAKE")
    {
      if (NvaiworkloadsId === 0) {
        valid = false;
        issues.push("Please select a AI WorkLoads option.");
      }
      if (NvaiprojectSizeId === 0) {
        valid = false;
        issues.push("Please select a Project Size option.");
      }
    }
    else{
      if (NvaiworkloadsId === 0) {
        valid = false;
        issues.push("Please select a AI WorkLoads option.");
      }
      if (NvaiprojectSizeId === 0) {
        valid = false;
        issues.push("Please select a Project Size option.");
      }
      if (nvaiDeploymentID === 0) {
        valid = false;
        issues.push("Please select a Deployment option.");
      }
      if (nvaiInfraID === 0) {
        valid = false;
        issues.push("Please select a Infrastructure option.");
      }
      if (nvaiGPUID === 0) {
        valid = false;
        issues.push("Please select a Nvidia GPUs option.");
      }
    }
    const { CompanyName } = this.state.OrgData;
   
   
   
    
    if (CompanyName === "" || !CompanyName) {
      valid = false;
      issues.push("Please provide your company name.");
    }
   
    if (CompanyName && CompanyName.indexOf('"') >= 0) {
      valid = false;
      issues.push("Company name cannot contain \".");
    }
    //to include change for restricting [ character]
    if (CompanyName && CompanyName.indexOf('[') >= 0) {
      valid = false;
      issues.push("Company name cannot contain [.");
    }
    if (CompanyName && CompanyName.indexOf('\\') >= 0) {
      valid = false;
      issues.push("Company name cannot contain \\.");
    }
  
 }
 else if (IsRWA03 && this.state.ProductFamily.toUpperCase()==="SPECTRUM-X") {
     
      
  
  const {
    



    stmxGPUID,
    stmxVendorID,
    stmxSwitchModelID ,
    stmxNosSwitchEvalID,
    stmxSwitchNOSID,
  






    
  } = this.state;
  const { CompanyName } = this.state.OrgData;
 

  if (stmxGPUID === 0) {
    valid = false;
    issues.push("Please select a Nvidia GPU option.");
  }
  if (stmxVendorID === 0) {
    valid = false;
    issues.push("Please select a GPU Server Vendor option.");
  }
  if (stmxSwitchModelID === 0) {
    valid = false;
    issues.push("Please select a Switch Model option.");
  }
  if (stmxNosSwitchEvalID === 0) {
    valid = false;
    issues.push("Please select a No. of Switches in Eval Env option.");
  }
  if (stmxSwitchNOSID === 0) {
    valid = false;
    issues.push("Please select a Switch NOS option.");
  }
  if (CompanyName === "" || !CompanyName) {
    valid = false;
    issues.push("Please provide your company name.");
  }
 
  if (CompanyName && CompanyName.indexOf('"') >= 0) {
    valid = false;
    issues.push("Company name cannot contain \".");
  }
  //to include change for restricting [ character]
  if (CompanyName && CompanyName.indexOf('[') >= 0) {
    valid = false;
    issues.push("Company name cannot contain [.");
  }
  if (CompanyName && CompanyName.indexOf('\\') >= 0) {
    valid = false;
    issues.push("Company name cannot contain \\.");
  }

}
   else if (IsRWA03 && this.state.ProductFamily.toUpperCase()==="UFM") {
     
      
  
    const {
      ufmNumberOfNodesID,
      ufmPlatformID,
      ufmDeploymentMethodID,
     
      
      
    } = this.state;
    const { CompanyName } = this.state.OrgData;
    if (ufmNumberOfNodesID === 0) {
      valid = false;
      issues.push("Please select a Number Of Nodes option.");
    }
    if (ufmDeploymentMethodID!==0 && ufmPlatformID === 0) {
      valid = false;
      issues.push("Please select a Platform option.");
    }
   
    if (ufmDeploymentMethodID === 0) {
      valid = false;
      issues.push("Please select a Deployment Method option.");
    }
   
    
    if (CompanyName === "" || !CompanyName) {
      valid = false;
      issues.push("Please provide your company name.");
    }
   
    if (CompanyName && CompanyName.indexOf('"') >= 0) {
      valid = false;
      issues.push("Company name cannot contain \".");
    }
    //to include change for restricting [ character]
    if (CompanyName && CompanyName.indexOf('[') >= 0) {
      valid = false;
      issues.push("Company name cannot contain [.");
    }
    if (CompanyName && CompanyName.indexOf('\\') >= 0) {
      valid = false;
      issues.push("Company name cannot contain \\.");
    }
  
 }
 else if (IsRWA03 && this.state.ProductFamily.toUpperCase()==="NETQSAAS") {
     
      
  
  const {
    
    NetworkOperatingSystem,        
    PremiseName,
    NumberOfSwicthes,
    NetQFirstName,
    NetQLastName,
    NetQEmail,
    
  } = this.state;
  const { CompanyName } = this.state.OrgData;
  if (NetworkOperatingSystem === 0) {
    valid = false;
    issues.push("Please select a Network Operating System option.");
  }

   if (!PremiseName) {
     valid = false;
     issues.push("Please provide premise name");
   }
   if (!NumberOfSwicthes) {
     valid = false;
     issues.push("Please provide number of switches");
   }

  
  if (CompanyName === "" || !CompanyName) {
    valid = false;
    issues.push("Please provide your company name.");
  }
 
  if (CompanyName && CompanyName.indexOf('"') >= 0) {
    valid = false;
    issues.push("Company name cannot contain \".");
  }
  //to include change for restricting [ character]
  if (CompanyName && CompanyName.indexOf('[') >= 0) {
    valid = false;
    issues.push("Company name cannot contain [.");
  }
  if (CompanyName && CompanyName.indexOf('\\') >= 0) {
    valid = false;
    issues.push("Company name cannot contain \\.");
  }

}
   else if (IsRWA03 && this.state.ProductFamily.toUpperCase()==="NETQ") {
     
      
  
    const {
      
      NetworkOperatingSystem,        
      PremiseName,
      NumberOfSwicthes,
      NetQFirstName,
      NetQLastName,
      NetQEmail,
      
    } = this.state;
    const { CompanyName } = this.state.OrgData;
    if (NetworkOperatingSystem === 0) {
      valid = false;
      issues.push("Please select a Network Operating System option.");
    }
 
    
    if (CompanyName === "" || !CompanyName) {
      valid = false;
      issues.push("Please provide your company name.");
    }
   
    if (CompanyName && CompanyName.indexOf('"') >= 0) {
      valid = false;
      issues.push("Company name cannot contain \".");
    }
    //to include change for restricting [ character]
    if (CompanyName && CompanyName.indexOf('[') >= 0) {
      valid = false;
      issues.push("Company name cannot contain [.");
    }
    if (CompanyName && CompanyName.indexOf('\\') >= 0) {
      valid = false;
      issues.push("Company name cannot contain \\.");
    }
  
 }
   else  if (IsRWA03) {
      const {
        CertifiedServer,
        VdiHypervisor,
        VdiSeats,
        VdiRemoting,
        OtherApplicationsVal,
        // PrimaryApplication,
        PrimaryApplicationMul,
        Gpu,
      } = this.state;
      const { CompanyName } = this.state.OrgData;

      if (CertifiedServer === 0) {
        valid = false;
        issues.push("Please select a certified server option.");
      }

      if (VdiHypervisor === 0) {
        valid = false;
        issues.push("Please select a VDI hypervisor option.");
      }

      if (VdiSeats === 0) {
        valid = false;
        issues.push("Please select a VDI seats option.");
      }

      if (VdiRemoting === 0) {
        valid = false;
        issues.push("Please select a VDI remoting option.");
      }     
      if (PrimaryApplicationMul.length === 0) {
        valid = false;
        issues.push("Please select a primary application option.");
      }
      // if (this.state.IsOtherSelected&&!OtherApplicationsVal) {
      //   valid = false;
      //   issues.push("Please enter other application name.");
      // }
      if (Gpu === 0) {
        valid = false;
        issues.push("Please select a NVIDIA GPU option.");
      }

      if (CompanyName === "" || !CompanyName) {
        valid = false;
        issues.push("Please provide your company name.");
      }
     
      if (CompanyName && CompanyName.indexOf('"') >= 0) {
        valid = false;
        issues.push("Company name cannot contain \".");
      }
      //to include change for restricting [ character]
      if (CompanyName && CompanyName.indexOf('[') >= 0) {
        valid = false;
        issues.push("Company name cannot contain [.");
      }
      if (CompanyName && CompanyName.indexOf('\\') >= 0) {
        valid = false;
        issues.push("Company name cannot contain \\.");
      }
    }

    //entitlement
    if (
      //  ( this.state.IsCommercial)
      (this.state.IsRWA01 || this.state.IsRWA02) &&
      this.state.PakId === "" &&
      this.state.Serial === ""
    ) {
      valid = false;
      issues.push("Please complete the entitlement section");
    }

    //primary contact
    if (this.state.EmailAddress === "") {
      valid = false;
      issues.push("Please provide an email address");
    }
    if (this.state.EmailAddress.toUpperCase() !== this.state.ConfirmEmailAddress.toUpperCase()) {
      valid = false;
      issues.push("Email and confirm email must match");
    }
    if (this.state.EmailValidationWrong) {
          
      valid = false;
      issues.push("Please provide valid Email Address");
      
    }
    
    if (
      this.state.FirstName === "" ||
      !validateNameRegex.test(this.state.FirstName)
    ) {
      valid = false;
      issues.push("Please provide valid first name");
    }
    if (
      this.state.LastName === "" ||
      !validateNameRegex.test(this.state.LastName)
    ) {
      valid = false;
      issues.push("Please provide valid last name");
    }
    if (
      (this.state.IsRWA01 || this.state.IsRWA02) &&
      // (this.state.IsCommercial)
      this.state.ClaimingEntitlementAs <= 0
    ) {
      valid = false;
      issues.push("Please select a claiming entitlement option ");
    }

    //primary contact details
    if (this.state.OrgData.CompanyPhone === "") {
      valid = false;
      issues.push("Please provide a phone number");
    }
    if (this.state.OrgData.CompanyJobRole <= 0) {
      valid = false;
      issues.push("Please select a job role");
    }

    //handle company addr

    if (this.state.ShowCompanySection && this.state.UseAltAddress) {
      if (this.state.AltStreet1 === "") {
        valid = false;
        issues.push("Please enter a street address");
      }
      if (this.state.AltCity === "") {
        valid = false;
        issues.push("Please enter a city");
      }
      if (this.state.AltCountry === 0) {
        valid = false;
        issues.push("Please select a location");
      }
      if (this.state.AltState === 0) {
        valid = false;
        issues.push("Please select a state");
      }
      if (this.state.AltZip === "") {
        valid = false;
        issues.push("Please enter a zip/postal code");
      }
    }

    if (IsRWA02 || IsRWA04) {
      const {
        ContainerAdmin,
        ContainerAltPhone,
        ContainerAltEmail,
        ContainerAltFirstName,
        ContainerAltLastName,
        ContainerShortCompanyName,
      } = this.state;

      // if (ContainerShortCompanyName === "") {
      //   valid = false;
      //   issues.push("Please provide an abbreviated company name.");
      // }

      //validate all remaining container fields
      if (ContainerAdmin === 2) {
        if (ContainerAltEmail === "") {
          valid = false;
          issues.push(
            "Please provide an email address for container administrator."
          );
        }

        if (ContainerAltFirstName === "") {
          valid = false;
          issues.push(
            "Please provide a first name for container administrator."
          );
        }

        if (ContainerAltLastName === "") {
          valid = false;
          issues.push(
            "Please provide a last name for container administrator."
          );
        }
      }
    }

    if (valid) {
      this.submitRegData(userInput);
    } else {
      this.setState({ AllowSubmit: true, Messages: issues });
    }
  }

  submitRegData(userInput) {

    let alt = this.state.UseAltAddress;

    //always submit these fields
    let reg = {
    
      PartnerUrlParam:this.state.IsEval?this.state.PartnerUrlParam:'',
      isLGSAddUser:
        this.state.ActivationToken && this.state.IsTokenValid ? true : false,
   
      licenseTypeId: this.state.LicenseTypeID,
      productFamilyId: this.state.ProductFamilyID,
      RWAGroupsID: this.state.RWAGroupID,
      emailID: this.state.EmailAddress,
      firstName: this.state.FirstName,
      lastName: this.state.LastName,
      phone: this.state.OrgData.CompanyPhone,
      jobRoleId: this.state.OrgData.CompanyJobRole,
      ProductFamilyCategoryID: this.state.ProductFamilyCategoryID,
      LicenseTypeCategoryID: this.state.LicenseTypeCategoryID,
      isSendNews: this.state.SendNews,
      street1: alt ? this.state.AltStreet1 : this.state.OrgData.CompanyStreet1,
      street2: alt ? this.state.AltStreet2 : this.state.OrgData.CompanyStreet2,
      city: alt ? this.state.AltCity : this.state.OrgData.CompanyCity,
      regURL: window.location.href,
      countryId: alt
        ? this.state.AltCountry
        : this.state.OrgData.CompanyCountry,
      stateId: alt ? this.state.AltState : this.state.OrgData.CompanyState,
      zipCode: alt ? this.state.AltZip : this.state.OrgData.CompanyZip,
      nid: this.state.nca_nid,
      nan : this.state.nca_nan,
      starfleet_id : this.state.starfleet_id,
      idp_id : this.state.idp_id,
      showPicker : this.state.showPicker,
      primaryOwnerEmail : this.state.primaryowneremail
    };

    if (this.state.UserInputNeeded) {
      reg.userInput = userInput;
    }
    //if address needed

    if (this.state.ShowCompanySection) {
      reg.companyDetail = {
        companyName: this.state.OrgData.CompanyName,
        street1: this.state.OrgData.CompanyStreet1,
        street2: this.state.OrgData.CompanyStreet2,
        city: this.state.OrgData.CompanyCity,
        countryId:
          this.state.OrgData.CompanyCountry === 0
            ? 1
            : this.state.OrgData.CompanyCountry,
        stateId:
          this.state.OrgData.CompanyState === 0
            ? 1
            : this.state.OrgData.CompanyState,
        zipCode: this.state.OrgData.CompanyZip,
        industryId:
          this.state.OrgData.CompanyIndustry === 0
            ? 7
            : this.state.OrgData.CompanyIndustry, //if industry wasn't presented, send 'other'
      };
    }
    if (reg.stateId === -1) {
      reg.stateId = null;
    }
    if (reg.companyDetail != null) {
      if (reg.companyDetail.stateId === -1) {
        reg.companyDetail.stateId = null;
      }
    }
    //submit if commericial
    if (this.state.IsRWA01 || this.state.IsRWA02) {
      if (this.state.PakId !== "") {
        reg.pakid = this.state.PakId;
      } else {
        reg.serialNumber = this.state.Serial;
      }

      reg.claimTypeId = this.state.ClaimingEntitlementAs;
      //reg.pecid = this.state.Pec;
    }
  if (this.state.IsRWA04) {
      

      reg.claimTypeId = this.state.ClaimingEntitlementAs;
     
    }
  
    if (this.state.IsRWA04&& this.state.ProductFamily.toUpperCase()==="NVAIENTERPRISE") {
      if(this.state.PartnerUrlParam && this.state.PartnerUrlParam.toUpperCase()==="SNOWFLAKE")
      {
      
        reg.NvaiworkloadsId = this.state.nvaiWorkLoadsID;
        reg.NvaiprojectSizeId=this.state.nvaiProjectSizeID;    
      }
      else
      {
      reg.NvaiworkloadsId = this.state.nvaiWorkLoadsID;
      reg.NvaiprojectSizeId=this.state.nvaiProjectSizeID;
      reg.NvaivirtualizationId = this.state.nvaiDeploymentID ;
      reg.NvaiinfrastructureId=this.state.nvaiInfraID;
      reg.NvainvidiaGpuId=this.state.nvaiGPUID;
    

      }
    } 
  else  if (this.state.IsRWA03&& this.state.ProductFamily.toUpperCase()==="SPECTRUM-X") {
     
      reg.StmxnvidiaGpuId = this.state.stmxGPUID;
      reg.StmxgpuserverVendorId=this.state.stmxVendorID;
      reg.StmxswitchModelId = this.state.stmxSwitchModelID ;
      reg.StmxnumberSwicthesId=this.state.stmxNosSwitchEvalID;
      reg.StmxswitchNosId=this.state.stmxSwitchNOSID;
      reg.STMXAIApplicationsName=this.state.stmxAIAPPName;
      reg.STMXNvidiaContactName=this.state.stmxNvidiaContact;
     
      
    } 
    else  if (this.state.IsRWA03&& this.state.ProductFamily.toUpperCase()==="OMNIVERSE") {
        if(this.state.PartnerUrlParam && this.state.PartnerUrlParam.toUpperCase()==="BOXX")
        {
        
          reg.BoxxNvidiaGPUId = this.state.BoxxNvidiaGPUId;
          reg.BoxxDeploymentTypeId=this.state.BoxxDeploymentTypeId;
          //reg.BoxxDeploymentId=this.state.BoxxDeploymentId;
       
          reg.BoxxCertifiedSystemId=this.state.BoxxCertifiedSystemId;
        }
        else
        {
        reg.NvidiaGPUUsedId = this.state.GPUDeployed;
        reg.DeploymentId=this.state.DeploymentOp;
        reg.primaryApplicationMulId = this.state.PrimaryApplicationMul ;
        reg.UseCaseId=this.state.UseCaseValue;
        reg.EstimatedNumberUsersId=this.state.EstimatedNoOfUsers;
        reg.OtherApplicationValue=this.state.OtherApplicationsVal;

        }
      }
   else   if (this.state.IsRWA03&& this.state.ProductFamily.toUpperCase()==="NVAIE") {
    
        reg.VMwarevSphere7U2EnterpriseEditionId = this.state.VMWareValue;
        reg.CompatibleHardwareId=this.state.CompatibleHardware;
     
        reg.EstimatedNumberUsersId=this.state.EstimatedNoOfUsers;
      

  
      }
      else   if (this.state.IsRWA03&& this.state.ProductFamily.toUpperCase()==="PARTNEROMNIVERSE") {       

          reg.PartnerDeploymentId = this.state.PartnerDeploymentId;
          reg.PartnerCertifiedSystemId=this.state.PartnerCertifiedSystemId;       
          reg.PartnerNvidiaGPUId=this.state.PartnerNvidiaGPUId;      
 
      }
      else   if (this.state.IsRWA03&& this.state.ProductFamily.toUpperCase()==="UFM") {       
     
        reg.UFMPlatformID = this.state.ufmPlatformID;
        reg.UFMDeploymentMethodID=this.state.ufmDeploymentMethodID;       
        reg.UFMNumberOfNodesID=this.state.ufmNumberOfNodesID;      

    }
      else   if (this.state.IsRWA03&& this.state.ProductFamily.toUpperCase()==="NETQ") {       

        
        reg.NetQNetworkOperatingSystemId = this.state.NetworkOperatingSystem;
        reg.NetQOtherNetworkElements=this.state.OtherNetworkElements;    
        
        
            
       

    }
    else   if (this.state.IsRWA03&& this.state.ProductFamily.toUpperCase()==="NETQSAAS") {       

        
          reg.NetQNetworkOperatingSystemId = this.state.NetworkOperatingSystem;
          reg.NetQOtherNetworkElements=this.state.OtherNetworkElements;  
          reg.NETQNumberOfSwicthes=this.state.NumberOfSwicthes;
          reg.NETQSitePremiseName=this.state.PremiseName; 

  }
  else   if (this.state.IsRWA03&& this.state.ProductFamily.toUpperCase()==="SPECTRUM-X") {       

    

}
    else if (this.state.IsRWA03 ) {
      reg.certifiedServerId = this.state.CertifiedServer;
      reg.nvidiaGPUId = this.state.Gpu;
      reg.vdiHyperVisorId = this.state.VdiHypervisor;
      reg.vdiRemotingClientId = this.state.VdiRemoting;
      reg.vdiSeatId = this.state.VdiSeats;     
      reg.primaryApplicationMulId =( this.state.PrimaryApplicationMul );
      reg.OtherApplicationValue=this.state.OtherApplicationsVal;
    }
    else if (this.state.IsRWA04) {
     
      reg.nvidiaGPUId = this.state.Gpu;
     
      reg.OtherApplicationValue=this.state.OtherApplicationsVal;
    }
  
    if (this.state.IsRWA03) {
      if (this.state.NCID) {
        reg.NCID = this.state.NCID;
      } else if (this.state.NVID) {
        reg.NCID = this.state.NVID;
      }
    }
    reg.contactTypeId = this.state.ContainerAdmin;

    //if dgx, send container fields
    if (
      // this.state.IsDgx
      this.state.IsRWA02 || this.state.IsRWA04
     ) {
      //reg.contactTypeId = this.state.ContainerAdmin;
      reg.NGCContainerRegistry = {
        firstName:
          this.state.ContainerAdmin !== 1
            ? this.state.ContainerAltFirstName
            : this.state.FirstName,
        lastName:
          this.state.ContainerAdmin !== 1
            ? this.state.ContainerAltLastName
            : this.state.LastName,
        phone:
          this.state.ContainerAdmin !== 1
            ? this.state.ContainerAltPhone
            : this.state.OrgData.CompanyPhone,
        //shortCompanyName: this.state.ContainerShortCompanyName,
        emailID:
          this.state.ContainerAdmin !== 1
            ? this.state.ContainerAltEmail
            : this.state.EmailAddress,
      };
    }
 

    axios

      .post(ApiMethods.POST_USER_REGISTRATION, reg, {
        headers: {
          "Content-Type": "application/json-patch+json",
          // "Content-Type": "application/x-www-form-urlencoded",
          accept: "application/json",
        },
      })
      .then((response) => {
        let msg = response.data.resMessage;
        

        if (msg && msg.includes("VGPUEvaluation@nvidia.com")) {
          this.setState({
            IsEvalMultipleAccount: true,
          });
        } else {
          this.setState({
            IsEvalMultipleAccount: false,
          });
        }
        
        if (msg && msg === ErrorCodes.MULTIPLEPECACCOUNTS) {
          msg = ErrorCodes.MultiplePecAccounts();
        } else if (msg && msg === ErrorCodes.LINKEDTOANOTHERPECACCOUNTS) {
          msg = ErrorCodes.LinkedtoAnotherPecAccounts();
        } else if (msg && msg === ErrorCodes.NPNACCOUNT) {
          msg = ErrorCodes.NPNAccounts();
        }
        else if (msg && msg === ErrorCodes.NGCORGEXISTS) {
          msg = ErrorCodes.NGCORGEXISTS;
        }
        
        else if(msg && msg === ErrorCodes.SUCCESSVGPU)
        {
          if(this.state.ProductFamily.toUpperCase()==="OMNIVERSE" && this.state.LicenseType.toUpperCase()==="EDU")
             {
                msg=ErrorCodes.renderEDUOMNIVERSEMessage();
             }

           else  if(this.state.ProductFamily.toUpperCase()==="OMNIVERSE")
             {
                msg=ErrorCodes.renderOMNIVERSEMessage();
             }
             else if(this.state.ProductFamily.toUpperCase()==="PARTNEROMNIVERSE")
             {
              msg=ErrorCodes.renderPARTNEROMNIVERSEMessage();
             }
             else if(this.state.ProductFamily.toUpperCase()==="NVAIE")
             {
              msg=ErrorCodes.renderNVAIEMessage();
             }
             else if(this.state.ProductFamily.toUpperCase()==="NETQ")
             {
              msg=ErrorCodes.renderNetqMessage();
              
             }
             else if(this.state.ProductFamily.toUpperCase()==="NETQSAAS")
             {
              msg=ErrorCodes.renderNetqSaasMessage();
              
             }
             else if(this.state.ProductFamily.toUpperCase()==="UFM")
             {
              msg=ErrorCodes.renderUFMMessage();
              
             }
             else if(this.state.ProductFamily.toUpperCase()==="NVAIENTERPRISE")
             {
              msg=ErrorCodes.renderNVAIEnterpriseMessage();
              
             }
             else if(this.state.ProductFamily.toUpperCase()==="SPECTRUM-X")
             {
              msg=ErrorCodes.renderSpectrumXMessage();
              
             }
             
        }

        this.setState({
          Messages: [msg],
          AllowSubmit: false,
          UserInputNeeded: response.data.needInput,
          RegistrationSubmitted: true,
          RegistrationSuccessMessage: msg,
        });
        this.clearStorage();
      })
      .catch((error) => {
      
        console.log(error.response);
        if (error.response.status === 500) {
          let responseStatusCode = ErrorCodes.INTERNAL_ERROR_CODE;
          if (error.response.data && error.response.data.resCodes) {
            responseStatusCode = error.response.data.resCodes;
          }
          let messageResponse = ErrorCodes.renderErrorMessage(
            responseStatusCode
          );

          this.setState({
            Messages: [messageResponse],
            AllowSubmit: false,
            UserInputNeeded: false,
            RegistrationSubmitted: true,
            RegistrationSuccessMessage: messageResponse,
          });
        } else if (error.response.status === 400) {
          let messageResponseBad = ErrorCodes.renderErrorMessage(
            error.response.data
          );
          if (error.response.data == ErrorCodes.PAKINVALID_ERROR_CODE || error.response.data ==ErrorCodes.PAKMAX_ERROR_CODE||error.response.data ==ErrorCodes.PAKMIN_ERROR_CODE) {
            messageResponseBad = ErrorCodes.render4001ErrorMessage(
              error.response.data
            );
          }
          if (error.response.data == ErrorCodes.PAKDUPLICATE_ERROR_CODE ) {
            messageResponseBad = ErrorCodes.render4009DuplicateErrorMessage(
              error.response.data
            );
          }
          if (error.response.data == ErrorCodes.PAKRESTRICTED_ERROR_CODE ) {
            messageResponseBad = ErrorCodes.render4010ErrorMessage(
              error.response.data
            );
          }
          this.setState({
            Messages: [messageResponseBad],
            AllowSubmit: false,
            UserInputNeeded: false,
            RegistrationSubmitted: true,
            RegistrationSuccessMessage: messageResponseBad,
          });
        } else {
          if (
            error.response.data.errors &&
            error.response.data.errors.length > 0
          ) {
            let e = error.response.data.errors.map((e) => {
              //return e.field + ": " + e.message;
              return e.message;
            });

            this.setState({
              Messages: e,
              MessageIcon: false,
              AllowSubmit: true,
            });
          } else {
            let messageResponse = ErrorCodes.renderErrorMessage(
              ErrorCodes.INTERNAL_ERROR_CODE
            );

            this.setState({
              Messages: [messageResponse],
              AllowSubmit: false,
              UserInputNeeded: false,
              RegistrationSubmitted: true,
              RegistrationSuccessMessage: messageResponse,
            });
          }
        }
        this.clearStorage();
      });
      
  }

  validateEntitlement() {
    const { Serial, PakId } = this.state;

    if (!Serial && !PakId) {
      //this.setState({IsLoaded : true});
      return;
    }
    let params = {};
    let p = "serial";

    if (this.state.Serial && this.state.Serial !== "") {
      params.serialNumber = this.state.Serial;
    } else {
      params.pakId = this.state.PakId;
      p = "pak";
    }
    params.productFamily=this.state.ProductFamilyName;
    params.licenseType=this.state.LicenseTypeName;
    axios
      .get(ApiMethods.GET_PEC_FROM_PAKID_SERIAL, {
        params,
      })
      .then((response) => {
        debugger;
        const ngcData = response.data;    
        if (ngcData) {
          this.setState({
              ngc_Create: ngcData.ngcCreate,             
               pak_Completed: ngcData.pakCompleted === null ? false : ngcData.pakCompleted,
               IsLoaded : true
          }, () => {
              console.log("loaded set from response");
              if(ngcData.ngcCreate && ngcData.pakCompleted)
                {
                  localStorage.setItem("primaryowneremail", ngcData.emailID);               
                  localStorage.setItem("sub", ngcData.sub); 
                  localStorage.setItem("idp_id", ngcData.idpID);    
                  localStorage.setItem("nid", ngcData.nid);
                  localStorage.setItem("nan", ngcData.nan);                                   
                }
              this.handleNCAData();
          });
      }
        if (ngcData.pecStatus === "PECValid") {
          this.setState({ ShowCompanySection: false, UseAltAddress: true });
        } else {
          this.setState({ ShowCompanySection: true });
        }

      })
      .catch((error) => {
        
        this.setState({
          Messages: ["Oops, something went wrong! Please try again later."],
          MessageIcon: false
        });
      });

    //if (this.state.IsRWA02) {
    //  const url =
    //    p === 'serial'
    //      ? ApiMethods.GET_STATUS_SERIAL
    //      : ApiMethods.GET_STATUS_PAK
    //  axios
    //    .get(url, {
    //      params
    //    })
    //    .catch(error => {
    //      if (
    //        error &&
    //        error.response &&
    //        error.response.status &&
    //        error.response.status === 404
    //      ) {
    //        this.setState({ ContainerNeeded: true })
    //      }
    //    })
    //}
  }

  clearStorage = () => {
    //localStorage.clear();
    localStorage.removeItem("userinfo");
    localStorage.removeItem("user");
    localStorage.removeItem("sub");
    localStorage.removeItem("idp_name");
    localStorage.removeItem("idp_id");
    localStorage.removeItem("issuer");
    localStorage.removeItem("external_id");
    localStorage.removeItem("preferred_username");
    localStorage.removeItem("updated_at");
    localStorage.removeItem("expire_at");
    localStorage.removeItem("issued_at");
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("ROCP_idToken");
    localStorage.removeItem("gettingAccessToken");
    localStorage.removeItem("CV");
    localStorage.removeItem("IsUserLoggedIn");
    localStorage.removeItem("nan");
    localStorage.removeItem("nid");
    localStorage.removeItem("email_verified");
    localStorage.removeItem("returnUrl");
    localStorage.removeItem("primaryowneremail");
  };

  getEvalNGCCreateDataByFamily() {
    if(this.state.ProductFamilyName !== undefined && this.state.LicenseTypeName !== undefined &&( this.state.LicenseTypeName.toUpperCase() == "EVAL" || this.state.LicenseTypeName.toUpperCase() == "EDU"))
    {
    axios.get(GET_NGCCreate + "?ProductFamily=" + this.state.ProductFamilyName + "&LicenseType=" + this.state.LicenseTypeName)
    .then((response) => {
      const d = response.data;
      if (d) {
        const ngcData = response.data;    
        if (ngcData) {
          this.setState({
              ngc_Create: ngcData.ngcCreate,    
              pak_Completed : ngcData.ngcCreate ? false : null,                    
              IsLoaded : true
          }, () => {
              console.log("loaded set from response");
              this.handleNCAData();      
          });
      }
      }
    })
    .catch ((error) => {
      this.setState({ IsLoaded: true });
    })
    }
    else{
        this.setState({ IsLoaded: true });
    }
  }

  handleCallback() {
    let expDate = localStorage.getItem("expire_at");
    expDate = parseInt(expDate);
    var curDate = new Date();
    var seconds = Math.round(curDate.getTime() / 1000);
    if (seconds > expDate) {
      this.clearStorage();
    }
        
    try {
      if (window.location.pathname == '/callback'){
        if (window.location.search !== "") {
        var searchParams = new URLSearchParams(window.location.search);

        if (searchParams.get("code")) {
          var code = searchParams.get("code");
          this.getAccessToken(code);
         
        }
      }
    } 
    } catch (error) {
      console.log(error);
    }
    if(this.state.IsLoaded == false){
      this.setState({IsLoaded : true})
    }
  }

  getAccessToken = async (code, reload = true) => {
    //var gettingAccessToken = localStorage.getItem("IsUserLoggedIn");
   // if (gettingAccessToken !== "inprogress") {
     // clearStorage();
     // localStorage.removeItem("token");
      var redirectUri = process.env.REACT_APP_BASE_NCA_Redirect_URI;
      if (code !== undefined) {
        localStorage.setItem("IsUserLoggedIn", "inprogress");

        var code_verifier = localStorage.getItem("CV");
        if (code_verifier === null) {
          window.location.assign(redirectUri);
        }
        const data = [
          `code=${code}`,
          `grant_type=authorization_code`,
          `code_verifier=${code_verifier}`,
          `redirect_uri=${redirectUri}`,
        ].join("&");

        var headerstr = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        fetch(process.env.REACT_APP_tokenEndpoint, {
          headers: headerstr,
          method: "POST",
          body: data,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              if (result !== null) {
                if (result["error"] === undefined) {
                  if (result["id_token"]) {
                    this.setSessionToken(JSON.stringify(result));
                    this.getUserInfo(result, reload);
                    localStorage.setItem("gettingAccessToken", false);
                  }
                } else {
                  localStorage.removeItem("token");
                  window.location.assign("/autherror");
                }
              }
            },
            (error) => {
              //setError(error);
              localStorage.setItem("IsUserLoggedIn", "false");
            }
          );
      }
    //}
  };

  setSessionToken = (token_str) => {
    if (token_str !== "") {
      localStorage.setItem("token", token_str);
      let token_obj = JSON.parse(token_str !== null ? token_str : "");
      localStorage.setItem("ROCP_idToken", token_obj.id_token);
    }
  };

  getUserInfo = async (token, reload = true) => {
    if (token) {
      var idToken = token.id_token;
      if (idToken !== null && idToken !== undefined) {
        var tokenobj = jwtDecode(idToken);
        //  console.log(tokenobj);        
          localStorage.setItem(
            "user",
            tokenobj.email.replace("@nvidia.com", "")
          );
          localStorage.setItem("email", tokenobj.email);        

        localStorage.setItem("userinfo", JSON.stringify(tokenobj));

        localStorage.setItem("sub", tokenobj.sub);
        localStorage.setItem("idp_name", tokenobj.idp_name);
        localStorage.setItem("email_verified", tokenobj.email_verified);
        localStorage.setItem("idp_id", tokenobj.idp_id);
        localStorage.setItem("issuer", tokenobj.iss);
        localStorage.setItem("external_id", tokenobj.external_id);
        localStorage.setItem("preferred_username", tokenobj.preferred_username);
        localStorage.setItem("updated_at", tokenobj.updated_at);
        localStorage.setItem("expire_at", tokenobj.exp);
        localStorage.setItem("issued_at", tokenobj.iat);
        localStorage.setItem("nid", tokenobj.nid);
        localStorage.setItem("nan", tokenobj.nan);
        
        if(reload){
          localStorage.setItem("IsUserLoggedIn", "true");
          var return_url = localStorage.getItem("returnUrl");
          window.location.assign(return_url);    
        }
      }
    }
  };

  getLookupData() {
let isBoxx=false;
    if((this.state.IsPartnerPF|| this.state.IsPartnerPFOmn)&& this.state.PartnerUrlParam.toUpperCase()==="BOXX")
    {
      isBoxx=true;
    }
   
    axios
      .get(ApiMethods.GET_LOOKUPS, {
        params: {
          productFamily: this.state.ProductFamily,
          isBoxx: isBoxx,
        },
      })
      .then((response) => {
        let d = response.data;
       
        d.claimTypes.unshift(this.defaultOption);
        d.countries.unshift(this.defaultCountryOption);
        d.jobRoles.unshift(this.defaultOption);
        d.industry.unshift(this.defaultOption);
        d.certifiedServers && d.certifiedServers.unshift(this.defaultOption);
        d.vdiHperVisors && d.vdiHperVisors.unshift(this.defaultOption);
        d.vdiSeats && d.vdiSeats.unshift(this.defaultOption);
        d.nvidiaGpus && d.nvidiaGpus.unshift(this.defaultOption);
        d.vdiRemotingClients && d.vdiRemotingClients.unshift(this.defaultOption);
        d.primaryApplications && d.primaryApplications.unshift(this.defaultOption);
        d.nvidiaGPUOptions && d.nvidiaGPUOptions.unshift(this.defaultOption);
        d.deployment && d.deployment.unshift(this.defaultOption);
        d.useCase && d.useCase.unshift(this.defaultOption);
        d.estimatedNumberOfUsers && d.estimatedNumberOfUsers.unshift(this.defaultOption);
        d.compatibleHardware && d.compatibleHardware.unshift(this.defaultOption);
        d.vmWare && d.vmWare.unshift(this.defaultOption);
        d.partnerCertifiedSystem && d.partnerCertifiedSystem.unshift(this.defaultOption);
        d.partnerDeployment && d.partnerDeployment.unshift(this.defaultOption);
        d.partnerNvidiaGPU && d.partnerNvidiaGPU.unshift(this.defaultOption);
        d.boxxCertifiedSystem && d.boxxCertifiedSystem.unshift(this.defaultOption);
        d.boxxDeployment && d.boxxDeployment.unshift(this.defaultOption);
        d.boxxDeploymentType && d.boxxDeploymentType.unshift(this.defaultOption);
        d.boxxNvidiaGPU && d.boxxNvidiaGPU.unshift(this.defaultOption);
        d.ufmDeploymentMethod && d.ufmDeploymentMethod.unshift(this.defaultOption);
        d.ufmNumberOfNodes && d.ufmNumberOfNodes.unshift(this.defaultOption);
        d.ufmPlatform && d.ufmPlatform.unshift(this.defaultOption);
        d.netQNetworkOperatingSystem && d.netQNetworkOperatingSystem.unshift(this.defaultOption);

        d.nvaiInfrastructure && d.nvaiInfrastructure.unshift(this.defaultOption);
        d.nvaigpu && d.nvaigpu.unshift(this.defaultOption);
        d.nvaiSize && d.nvaiSize.unshift(this.defaultOption);
        d.nvaiVirtualization && d.nvaiVirtualization.unshift(this.defaultOption);
        d.nvaiWorkloads && d.nvaiWorkloads.unshift(this.defaultOption);
        d.stmxgpu && d.stmxgpu.unshift(this.defaultOption);
        d.stmxModel && d.stmxModel.unshift(this.defaultOption);
        d.stmxNos && d.stmxNos.unshift(this.defaultOption);
        d.stmxSwitch && d.stmxSwitch.unshift(this.defaultOption);
        d.stmxVendor && d.stmxVendor.unshift(this.defaultOption);
        
        
        
      
        
        // const { ProductFamily: pf } = this.state;
if(response.data.primaryApplications && response.data.primaryApplications.length>1)
{
  response.data.primaryApplications.shift();
}

        this.setState({
          // nvaiGPUOptions:response.data.nvaigpu?response.data.nvaigpu:[],
          // nvaiInfraOptions:response.data.nvaiInfrastructure?response.data.nvaiInfrastructure:[],
          // nvaiSizeOptions:response.data.nvaiSize?response.data.nvaiSize:[],
          // nvaiVirtualOptions:response.data.nvaiVirtualization?response.data.nvaiVirtualization:[],
          // nvaiWorkloadsOptions:response.data.nvaiWorkloads?response.data.nvaiWorkloads:[],
          // stmxGPUOptions:response.data.stmxgpu?response.data.stmxgpu:[],
          // stmxModelOptions:response.data.stmxModel?response.data.stmxModel:[],
          // stmxNosOptions:response.data.stmxNos?response.data.stmxNos:[],
          // stmxSwitchOptions:response.data.stmxSwitch?response.data.stmxSwitch:[],
          // stmxVendorOptions:response.data.stmxVendor?response.data.stmxVendor:[],
          nvaiGPUOptions:d.nvaigpu?d.nvaigpu:[],
          nvaiInfraOptions:d.nvaiInfrastructure?d.nvaiInfrastructure:[],
          nvaiSizeOptions:d.nvaiSize?d.nvaiSize:[],
          nvaiVirtualOptions:d.nvaiVirtualization?d.nvaiVirtualization:[],
          nvaiWorkloadsOptions:d.nvaiWorkloads?d.nvaiWorkloads:[],
          stmxGPUOptions:d.stmxgpu?d.stmxgpu:[],
          stmxModelOptions:d.stmxModel?d.stmxModel:[],
          stmxNosOptions:d.stmxNos?d.stmxNos:[],
          stmxSwitchOptions:d.stmxSwitch?d.stmxSwitch:[],
          stmxVendorOptions:d.stmxVendor?d.stmxVendor:[],



          Countries: response.data.countries,
          ClaimingEntitlementAsOptions: response.data.claimTypes,
          JobRoleOptions: response.data.jobRoles,
          IndustryOptions: response.data.industry,
          ContainerAdminOptions: response.data.contactTypes,
          CertifiedServerOptions: response.data.certifiedServers?  response.data.certifiedServers:null,
          HypervisorOptions: response.data.vdiHperVisors?response.data.vdiHperVisors:null,
          VdiSeatsOptions: response.data.vdiSeats?response.data.vdiSeats:null,
           GpuOptions: response.data.nvidiaGpus?response.data.nvidiaGpus:null,
           VdiRemotingOptions: response.data.vdiRemotingClients?response.data.vdiRemotingClients:null,
           PrimaryApplicationOptions: response.data.primaryApplications?response.data.primaryApplications:null,
           NvidiaGPUDeployedOptions: response.data.nvidiaGPUOptions? response.data.nvidiaGPUOptions:null,
           DeploymentOptions:response.data.deployment?response.data.deployment:null,
           UseCaseOptions:response.data.useCase?response.data.useCase:null,
           EstimatedUsersOptions:response.data.estimatedNumberOfUsers?response.data.estimatedNumberOfUsers:null,  
           CompatibleHardwareOptions: response.data.compatibleHardware?response.data.compatibleHardware:null,
           VMWareOptions:response.data.vmWare?response.data.vmWare:null,
           PartnerNvidiaGPUOptions:response.data.partnerNvidiaGPU?response.data.partnerNvidiaGPU:null,
           PartnerDeploymentOptions:response.data.partnerDeployment?response.data.partnerDeployment:null,
           PartnerCertifiedSystemOptions:response.data.partnerCertifiedSystem?response.data.partnerCertifiedSystem:null,
           BoxxDeploymentTypeOptions:response.data.boxxDeploymentType?response.data.boxxDeploymentType:null,
           BoxxNvidiaGPUOptions:response.data.boxxNvidiaGPU?response.data.boxxNvidiaGPU:null,
           BoxxDeploymentOptions:response.data.boxxDeployment?response.data.boxxDeployment:null,
           BoxxCertifiedSystemOptions:response.data.boxxCertifiedSystem?response.data.boxxCertifiedSystem:null,
           NETQOSOptions:response.data.netQNetworkOperatingSystem?response.data.netQNetworkOperatingSystem:null,
           ufmDeploymentMethodOptions:response.data.ufmDeploymentMethod?response.data.ufmDeploymentMethod:null,
           ufmNumberOfNodesOptions:response.data.ufmNumberOfNodes?response.data.ufmNumberOfNodes:null,
           ufmPlatformOptions:response.data.ufmPlatform?response.data.ufmPlatform:null,
          // ufmPlatformOptionsModified:response.data.ufmPlatform?d.ufmPlatformOptionsModified.unshift(this.defaultOption):null,
          Messages: [],
        });
      })
      .catch((error) => {
        
        this.setState({
          Messages: ["Oops, something went wrong! Please try again later."],
          MessageIcon: false,
        });
      });
  }
  validateLGSEmail() {
    let errorMessage = [];

    const regexEmailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      regexEmailValidation.test(String(this.state.EmailAddress).toLowerCase())
    ) {
      this.setState({
        AllowSubmit: true,
        Messages: errorMessage,
      });
      if (this.state.IsTokenPresent && this.state.IsTokenValid) {
        let lgsRequest = {
          ActivationToken: this.state.ActivationToken,
          EmailAddress: this.state.EmailAddress,
        };
        axios
          .post(ApiMethods.POST_LGSEMAIL, lgsRequest)
          .then((response) => {
            this.setState({
              IsLGSEmailValid: true,
              AllowSubmit: true,
              Messages: "",
            });
          })
          .catch((error) => {
            errorMessage.push(
              "Invalid Email. Please contact your system Administrator"
            );
            this.setState({
              AllowSubmit: false,
              Messages: errorMessage,
              IsLGSEmailValid: false,
            });
          });
      }
    } else {
      errorMessage.push("Please provide valid Email Address.");
      this.setState({
        AllowSubmit: false,
        Messages: errorMessage,
        IsLGSEmailValid: false,
      });
    }
  }
  validateLGSActivationToken() {
    if (this.state.IsTokenPresent) {
      axios
        .get(ApiMethods.GET_ACTIVATIONTOKEN, {
          params: {
            activationToken: this.state.ActivationToken,
          },
        })
        .then((response) => {
          this.setState({ IsTokenValid: true, IsLGSRender: true });
        })
        .catch((error) => {
        
          let messageResponses = error.response.data.message;
          
          if(messageResponses && messageResponses.includes("5001"))
          {
             messageResponses=ErrorCodes.renderLGSInvalidTokenMessage(
        
              );
            
          }
          this.setState({
            Messages: [messageResponses],
            AllowSubmit: false,
            UserInputNeeded: false,
            RegistrationSubmitted: true,
            RegistrationSuccessMessage: messageResponses,
            IsLGSRender: true,
          });
        });
    }
  }
  validateEmailAddressThroughAPI() {
   let isvalidWhiteList=false;
    if(this.state.EmailAddress.length>1)
    {
      var emailAddressString=this.state.EmailAddress;
      if (this.state.EmailAddress.includes("+") && this.state.EmailAddress.toUpperCase().includes("@GMAIL.COM"))
      {
       
        emailAddressString=emailAddressString.replace(/\+.*?@/, '@')
        
      };
   
      for (const element of this.config.EmailDomainWhiteList) {
       
        if(this.state.EmailAddress.toUpperCase().indexOf(element, this.state.EmailAddress.length - element.length) !== -1){
     
          isvalidWhiteList=true;
          
      }
    }
    
   if(isvalidWhiteList)
   {
    this.setState({ EmailValidationLoading: false, EmailValidationCheck: true,EmailValidationWrong:false });
   }
   else{
    this.setState({ EmailValidationLoading: true ,EmailValidationCheck: false,EmailValidationWrong:false});
    axios
      .get(ApiMethods.ValidateEmailAPI, {
        params: {
          emailAddress: emailAddressString          
        },
      })
      .then((response) => {
   
        if(response.data!=="undefined" && response.data==="Invalid")
        {
          this.setState({ EmailValidationLoading: false, EmailValidationCheck: false,EmailValidationWrong:true });
        }
        else if(response.data!=="undefined" && response.data==="Valid")
        {
          this.setState({ EmailValidationLoading: false, EmailValidationCheck: true,EmailValidationWrong:false });
        }
        else{
          this.setState({ EmailValidationLoading: false, EmailValidationCheck: false,EmailValidationWrong:false });
        }
       
      })
      .catch((error) => {
      
        
        this.setState({ EmailValidationLoading: false, EmailValidationWrong: false,EmailValidationCheck:false });
      });
   }
    
   
      }
      else{
        this.setState({ EmailValidationLoading: false, EmailValidationWrong: false,EmailValidationCheck:false });
      }
  }
  getRWAMappingData() {
    const productFamilyName = this.state.ProductFamilyName;
    const licenseTypeName = this.state.LicenseTypeName;
    axios
      .get(ApiMethods.GET_RWAMAPPING)
      .then((response) => {
        const d = response.data;

        const rwaIDs = d.find(
          (e) =>
            e.productFamilyName.toUpperCase() ===
              productFamilyName.toUpperCase() &&
            e.licenseTypeName.toUpperCase() === licenseTypeName.toUpperCase()
        );

        if (rwaIDs.rwaGroupName === "RWA01") {
          this.setState({
            IsRWA01: true,
            RWAGroupID: rwaIDs.rwaGroupID,
            LicenseTypeID: rwaIDs.licenseTypeID,
            ProductFamilyID: rwaIDs.productFamilyID,
            ProductFamilyCategoryID: rwaIDs.productFamilyCategoryID,

            LicenseTypeCategoryID: rwaIDs.licenseTypeCategoryID,
            IsGrid: rwaIDs.productFamilyCategoryID === 1,
            IsDgx: rwaIDs.productFamilyCategoryID === 2,
            IsEval: rwaIDs.licenseTypeCategoryID === 1,
            IsCommercial: rwaIDs.licenseTypeCategoryID === 2,
            IsVgpu: rwaIDs.productFamilyName.toUpperCase() === "VGPU",
          });
        } else if (rwaIDs.rwaGroupName === "RWA02") {
          this.setState({
            IsRWA02: true,
            RWAGroupID: rwaIDs.rwaGroupID,
            LicenseTypeID: rwaIDs.licenseTypeID,
            ProductFamilyID: rwaIDs.productFamilyID,
            ProductFamilyCategoryID: rwaIDs.productFamilyCategoryID,
            LicenseTypeCategoryID: rwaIDs.licenseTypeCategoryID,
            IsGrid: rwaIDs.productFamilyCategoryID === 1,
            IsDgx: rwaIDs.productFamilyCategoryID === 2,
            IsEval: rwaIDs.licenseTypeCategoryID === 1,
            IsCommercial: rwaIDs.licenseTypeCategoryID === 2,
            IsVgpu: rwaIDs.productFamilyName.toUpperCase() === "VGPU",
          });
        } else if (rwaIDs.rwaGroupName === "RWA03") {
          this.setState({
            IsRWA03: true,
            RWAGroupID: rwaIDs.rwaGroupID,
            LicenseTypeID: rwaIDs.licenseTypeID,
            ProductFamilyID: rwaIDs.productFamilyID,
            ProductFamilyCategoryID: rwaIDs.productFamilyCategoryID,
            LicenseTypeCategoryID: rwaIDs.licenseTypeCategoryID,
            IsGrid: rwaIDs.productFamilyCategoryID === 1,
            IsDgx: rwaIDs.productFamilyCategoryID === 2,
            IsEval: rwaIDs.licenseTypeCategoryID === 1,
            IsCommercial: rwaIDs.licenseTypeCategoryID === 2,
            IsVgpu: rwaIDs.productFamilyName.toUpperCase() === "VGPU",
          });
        }
        else if (rwaIDs.rwaGroupName === "RWA04") {
          this.setState({
            IsRWA04: true,
            RWAGroupID: rwaIDs.rwaGroupID,
            LicenseTypeID: rwaIDs.licenseTypeID,
            ProductFamilyID: rwaIDs.productFamilyID,
            ProductFamilyCategoryID: rwaIDs.productFamilyCategoryID,
            LicenseTypeCategoryID: rwaIDs.licenseTypeCategoryID,
            IsGrid: rwaIDs.productFamilyCategoryID === 1,
            IsDgx: rwaIDs.productFamilyCategoryID === 2,
            IsEval: rwaIDs.licenseTypeCategoryID === 1,
            IsCommercial: rwaIDs.licenseTypeCategoryID === 2,
            IsVgpu: rwaIDs.productFamilyName.toUpperCase() === "VGPU",
          });
        }
      })
      .catch((error) => {
        
        this.setState({
          Messages: ["Oops, something went wrong! Please try again later."],
          MessageIcon: false,
        });
      })
      .then(() => {
         var gettingAccessToken = localStorage.getItem("IsUserLoggedIn");
        if((productFamilyName == undefined || licenseTypeName == undefined) && gettingAccessToken !== "inprogress" ) {
            this.setState({ IsLoaded: true });
        }
        this.validateEntitlement();
      })
      // .then(() => {
      //   this.setState({ IsLoaded: true });
      // });
  }
  getStatesByCountry(id, useAlt) {
    let selected = this.state.Countries.filter(
      (country) => parseInt(country.id) === parseInt(id)
    );

    let countryCode = selected[0].code;
    axios
      .get(ApiMethods.GET_STATE_FROM_COUNTRY, {
        params: {
          countryCode: countryCode,
        },
      })
      .then((response) => {
        if (isArray(response.data) && response.data.length > 0) {
          response.data.unshift(this.defaultStateOption);
        }
        if (useAlt) {
          if (isArray(response.data) && response.data.length > 0) {
            this.setState({ AltState: parseInt(response.data[0].id) });
          }
          this.setState({ AltStates: response.data, Messages: [] });
        } else {
          if (isArray(response.data) && response.data.length > 0) {
            let orgData = Object.assign({}, this.state.OrgData);
            orgData.CompanyState = parseInt(response.data[0].id);
            this.setState({ OrgData: orgData });
          }

          this.setState({ States: response.data, Messages: [] });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          if (useAlt) {
            this.setState({
              AltStates: [{ id: -1, code: "", stateName: "N/A" }],
              AltState: -1,
            });
          } else {
            let orgData = Object.assign({}, this.state.OrgData);
            orgData.CompanyState = -1;
            this.setState({
              OrgData: orgData,
              States: [{ id: -1, code: "", stateName: "N/A" }],
            });
          }
        } else {
          
          this.setState({
            Messages: ["Something went wrong!"],
            MessageIcon: false,
            States: [this.defaultStateOption],
          });
        }
      });
  }

  componentDidMount() {     
    this.handleNCAData();
    if (this.state.PakIdProvided) {
    //  this.validateEntitlement();
    }
  }

  render() {
    const {
      IsRWA01,
      IsRWA02,
      IsRWA03,
      IsRWA04,
      RegistrationSubmitted,
      IsLoaded,
    } = this.state;

    if (!IsLoaded) {
      return <div>loading...</div>;
    }
    if (this.state.IsTokenPresent && !this.state.IsLGSRender) {
      return <div>loading...</div>;
    }

    if (
      !IsRWA01 &&
      !IsRWA02 &&
      !IsRWA03 &&
      !IsRWA04 &&
      !RegistrationSubmitted &&
      IsLoaded
    ) {
      return <Error />;
    }

    if (RegistrationSubmitted) {
      const { RegistrationSuccessMessage, UserInputNeeded } = this.state;
      if (this.state.IsEvalMultipleAccount) {
        return (
          <div>
            <AppHeader bannerText={"NVIDIA ENTERPRISE ACCOUNT REGISTRATION"} />
            <div className='submit-content'>
              <h2>
                Your e-mail ID is linked to multiple accounts in our
                system.Please reach out to your{" "}
                <a href={this.config.NvidiaCertifiedPartnerLink}>
                  NVIDIA Certified Partner
                </a>{" "}
                or
                <a href={this.config.NvidiaContactUsLink}> contact us</a>.
              </h2>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <AppHeader bannerText={"NVIDIA ENTERPRISE ACCOUNT REGISTRATION"} />
            <div className='submit-content'>
              <h2>{RegistrationSuccessMessage}</h2>
              {UserInputNeeded && (
                <Button.Group>
                  <Button onClick={this.handleNoClick} neutral>
                    Cancel Registration
                  </Button>
                  <Button onClick={this.handleYesClick} primary>
                    Link to new account
                  </Button>
                </Button.Group>
              )}
            </div>
          </div>
        );
      }
    }

    //alt country/state are readonly textboxes when using 'same address', hence show string instead of int value
    const altDisplayCountry =
      this.state.OrgData.CompanyCountry === 0
        ? ""
        : this.state.Countries.filter(
            (x) => x.id === this.state.OrgData.CompanyCountry
          )[0].countryName;

    let altDisplayState = "";
    if (this.state.States.length > 1 && this.state.OrgData.CompanyState > 0) {
      if (
        this.state.States.filter(
          (x) => x.id === this.state.OrgData.CompanyState
        ).length > 0
      ) {
        altDisplayState = this.state.States.filter(
          (x) => x.id === this.state.OrgData.CompanyState
        )[0].stateName;
      }
    }
    //check for dgx
    const showSerial = this.state.IsRWA02 && this.state.PakIdProvided === false;
    const UseAltContainerInfo = this.state.ContainerAdmin !== 1;

    const isVgpu = this.state.IsVgpu;

    return (
      <div>
      <AppHeader bannerText={"NVIDIA ENTERPRISE ACCOUNT REGISTRATION"} />       
      {this.state.ngc_Create && !this.state.nca_nan && !this.state.pak_Completed ? <Start/> : 
      <>   
      {this.state.IsCommercial && this.state.PakIdProvided && (
        <MetaTags>
          <meta name='robots' content='noindex' />
        </MetaTags>
      )}
      
      {  (IsRWA04 || IsRWA03)&&(<ConfirmationDialog 
      BoxxProductFamily={(this.state.IsPartnerPFOmn && this.state.PartnerUrlParam && this.state.PartnerUrlParam.toUpperCase()==="BOXX")?true:false}
      PartnerURLVal={this.state.PartnerUrlParam}
      handleCancelClickEvent={this.handleCancelClickPop}
       link={
        IsRWA03
          ? this.config.HelpEmailAddresses.GRID.link
          : this.config.HelpEmailAddresses.DGX.link
      }
      linktext={
        IsRWA03
          ? this.config.HelpEmailAddresses.GRID.text
          : this.config.HelpEmailAddresses.DGX.text
      }
      idmlogin={this.config.IDMLogin}
      isrwa03={this.state.IsRWA03?1:0} 
      isrwa04={this.state.IsRWA04?1:0} 
      title={ IsRWA03 ? "NVIDIA Enterprise Account Registration" : "" }
      licensetype={this.state.LicenseType}
      productfamily={this.state.ProductFamily}></ConfirmationDialog>)}
        
      
   {!IsRWA03&&(

      
      <InfoBlock
        title={isVgpu && IsRWA03 ? "Start your 90-day vGPU trial" : ""}
        IsRWA03={this.state.IsRWA03}
        IsRWA02={this.state.IsRWA02}
        link={
          IsRWA03
            ? this.config.HelpEmailAddresses.GRID.link
            : this.config.HelpEmailAddresses.DGX.link
        }
        linkText={
          IsRWA03
            ? this.config.HelpEmailAddresses.GRID.text
            : this.config.HelpEmailAddresses.DGX.text
        }
        idmLogin={this.config.IDMLogin}
      />
      )}
      {/* for commercial */}
      {(this.state.IsRWA01 || this.state.IsRWA02) && (
        <Entitlement
          pakId={this.state.PakId}
          serial={this.state.Serial}
          allowPakIdEdit={this.state.AllowPakIdEdit}
          allowSerialEdit={this.state.AllowSerialEdit}
          showSerial={showSerial}
          onPakIdChange={this.handlePakIdChange}
          onSerialChange={this.handleSerialChange}
          onPakIdBlur={this.handlePakIdBlur}
          onSerialBlur={this.handleSerialBlur}
        />
      )}
      <PrimaryContact
        IsRWA03={this.state.IsRWA03}
        handleEmailChange={this.handleEmailChange}
        handleEmailChangeConfirm={this.handleEmailChangeConfirm}
        handleEmailConfirmPaste={this.handleEmailConfirmPaste}
        handleEmailBlur={this.handleEmailBlur}
        handleFirstNameChange={this.handleFirstNameChange}
        handleLastNameChange={this.handleLastNameChange}
        handleClaimingEntitlementAsChange={
          this.handleClaimingEntitlementAsChange
        }
        claimingEntitlementAsOptions={this.state.ClaimingEntitlementAsOptions}
        emailAddress={this.state.EmailAddress}
        emailAddressConfirm={this.state.ConfirmEmailAddress}
        firstName={this.state.FirstName}
        lastName={this.state.LastName}
        lockEmail={this.state.lockEmail}
        lockFirstName={this.state.lockFirstName}
        lockLastName={this.state.lockLastName}
        emailValidationLoading={this.state.EmailValidationLoading}
        emailValidationCheck={this.state.EmailValidationCheck}
        emailValidationWrong={this.state.EmailValidationWrong}

      />
      {this.state.ShowCompanySection && (
        <CompanyInfo
          orgData={this.state.OrgData}
          handleCompanyNameChange={this.handleCompanyNameChange}
          handleCompanyCountryChange={this.handleCompanyCountryChange}
          handleCompanyStateChange={this.handleCompanyStateChange}
          handleCompanyIndustryChange={this.handleCompanyIndustryChange}
          isOrgLock={this.state.IsOrgLock}
          claimingEntitlementAs={this.state.ClaimingEntitlementAs}
          pecProvided={this.state.ShowCompanySection}
          handleStateChange={this.handleDetailsStateChange}
          handleCountryChange={this.handleDetailsCountryChange}
          handleStreet1Change={this.handleDetailsStreet1Change}
          handleStreet2Change={this.handleDetailsStreet2Change}
          handleCityChange={this.handleDetailsCityChange}
          handleZipChange={this.handleDetailsZipChange}
          countries={this.state.Countries}
          states={this.state.States}
          industryOptions={this.state.IndustryOptions}
        />
      )}
      {this.state.UseAltAddress ? (
        <PrimaryContactDetails
          street1={this.state.AltStreet1}
          street2={this.state.AltStreet2}
          city={this.state.AltCity}
          country={this.state.AltCountry}
          countries={this.state.Countries}
          state={this.state.AltState}
          states={this.state.AltStates}
          zip={this.state.AltZip}
          isEval={this.state.IsEval}
          phone={this.state.OrgData.CompanyPhone}
          jobRole={this.state.OrgData.CompanyJobRole}
          jobRoles={this.state.JobRoleOptions}
          partnerValuePresent={this.state.IsPartnerURLPresent}
          partnerValue={this.state.PartnerUrlParam}
          handlePartnerChange={this.handlePartnerValueChange}
          handleStateChange={this.handleAltStateChange}
          handleCountryChange={this.handleAltCountryChange}
          handleJobRoleChange={this.handleDetailsJobRoleChange}
          handleStreet1Change={this.handleAltStreet1Change}
          handleStreet2Change={this.handleAltStreet2Change}
          handleCityChange={this.handleAltCityChange}
          handlePhoneChange={this.handleDetailsPhoneChange}
          handleZipChange={this.handleAltZipChange}
          pecProvided={!this.state.ShowCompanySection}
          useAltAddress={this.state.UseAltAddress}
          handleAltAddressChange={this.handleAltAddressChange}
        />
      ) : (
        <PrimaryContactDetails
          street1={this.state.OrgData.CompanyStreet1}
          street2={this.state.OrgData.CompanyStreet2}
          partnerValuePresent={this.state.IsPartnerURLPresent}
          city={this.state.OrgData.CompanyCity}
          partnerValue={this.state.PartnerUrlParam}
          handlePartnerChange={this.handlePartnerValueChange}
          isEval={this.state.IsEval}
          //country={this.state.OrgData.CompanyCountry}
          country={altDisplayCountry}
          countries={this.config.Countries}
          //state={this.state.OrgData.CompanyState}
          state={altDisplayState}
          states={this.state.States}
          zip={this.state.OrgData.CompanyZip}
          phone={this.state.OrgData.CompanyPhone}
          jobRole={this.state.OrgData.CompanyJobRole}
          jobRoles={this.state.JobRoleOptions}
          handleStateChange={this.handleDetailsStateChange}
          handleCountryChange={this.handleDetailsCountryChange}
          handleJobRoleChange={this.handleDetailsJobRoleChange}
          handleStreet1Change={this.handleDetailsStreet1Change}
          handleStreet2Change={this.handleDetailsStreet2Change}
          handleCityChange={this.handleDetailsCityChange}
          handlePhoneChange={this.handleDetailsPhoneChange}
          handleZipChange={this.handleDetailsZipChange}
          pecProvided={!this.state.ShowCompanySection}
          useAltAddress={this.state.UseAltAddress}
          handleAltAddressChange={this.handleAltAddressChange}
        />
      )}
      {/* {this.state.IsEval && this.state.IsGrid && ( */}
      {(this.state.IsRWA03 || this.state.IsRWA04) &&(
        <Environment
           nvaiGPUOptions={this.state.nvaiGPUOptions}
           nvaiInfraOptions={this.state.nvaiInfraOptions}
           nvaiSizeOptions={this.state.nvaiSizeOptions}
           nvaiVirtualOptions={this.state.nvaiVirtualOptions}
           nvaiWorkloadsOptions={this.state.nvaiWorkloadsOptions}
           stmxGPUOptions={this.state.stmxGPUOptions}
           stmxModelOptions={this.state.stmxModelOptions}
           stmxNosOptions={this.state.stmxNosOptions}
           stmxSwitchOptionsUOptions={this.state.stmxSwitchOptions}
           stmxVendorOptions={this.state.stmxVendorOptions}
           handleNVAIGPUChange={this.handleNVAIGPUChange}
           handleNVAIInfraChange={this.handleNVAIInfraChange}
           handleNVAIDeploymentChange={this.handleNVAIDeploymentChange}
           handleNVAIWorkLoadsChange={this.handleNVAIWorkLoadsChange}
           handleNVAIProjectSizeChange={this.handleNVAIProjectSizeChange}
           handleNSTMXGPUChange={this.handleNSTMXGPUChange}
           handleSTMXVendorChange={this.handleSTMXVendorChange}
           handleSTMXSwitchModelChange={this.handleSTMXSwitchModelChange}
           handleSTMXNosSwitchEvalChange={this.handleSTMXNosSwitchEvalChange}
           handleSTMXSwitchNOSChange={this.handleSTMXSwitchNOSChange}
           handleSTMXAIAppChange={this.handleSTMXAIAppChange}
           handleSTMXNvidiaContactChange={this.handleSTMXNvidiaContactChange}
           stmxNvidiaContactVal={this.state.stmxNvidiaContact}
           stmxAIAppVal={this.state.stmxAIAPPName}
        
          productFamily={this.state.ProductFamily}
          serverOptions={this.state.CertifiedServerOptions}
          hypervisorOptions={this.state.HypervisorOptions}
          seatOptions={this.state.VdiSeatsOptions}
          gpuOptions={this.state.GpuOptions}
          remotingOptions={this.state.VdiRemotingOptions}
          primaryAppOptions={this.state.PrimaryApplicationOptions}
          NvidiaGpusDeployedOptions={this.state.NvidiaGPUDeployedOptions}
          deploymentOptions={this.state.DeploymentOptions}
          useCaseOptions={this.state.UseCaseOptions}
          estimatedUserOptions={this.state.EstimatedUsersOptions}
          otherApplicationsVal={this.state.OtherApplicationsVal}
          isOtherSelected={this.state.IsOtherSelected}
          CompatibleHardwareOptions={this.state.CompatibleHardwareOptions}
          VMWareOptions={this.state.VMWareOptions}
          PartnerCertifiedOptions={this.state.PartnerCertifiedSystemOptions}
          PartnerDeploymentOptions={this.state.PartnerDeploymentOptions}
          PartnerNvidiaGPUOptions={this.state.PartnerNvidiaGPUOptions}
          isBoxx={this.state.PartnerUrlParam.toUpperCase()==="BOXX"?true:false}
          isSnowflake={this.state.PartnerUrlParam.toUpperCase()==="SNOWFLAKE"?true:false}
          BoxxDeploymentTypeOptions={this.state.BoxxDeploymentTypeOptions}
          BoxxDeploymentOptions={this.state.BoxxDeploymentOptions}
          BoxxCertifiedSystemOptions={this.state.BoxxCertifiedSystemOptions}
          BoxxNvidiaGPUOptions={this.state.BoxxNvidiaGPUOptions}
          NETQOSOptions={this.state.NETQOSOptions}
          isNetqSaas={this.state.IsNetQSaas}
          ufmPlatformOptions={this.state.ufmPlatformOptionsModified}
          ufmNumberOfNodesOptions={this.state.ufmNumberOfNodesOptions}
          ufmDeploymentMethodOptions={this.state.ufmDeploymentMethodOptions}
          handleUFMDeploymentMethodChange={this.handleUFMDeploymentMethodChange}
          handleUFMNumberOfNodesChange={this.handleUFMNumberOfNodesChange}
          handleUFMPlatformChange={this.handleUFMPlatformChange}       
          IsNetQSameUser={this.state.IsNetQSameUser}
          NetQFirstName={this.state.IsNetQSameUser?this.state.FirstName:this.state.NetQFirstName}
          NetQLastName={this.state.IsNetQSameUser?this.state.LastName:this.state.NetQLastName}
          NetQEmail={this.state.IsNetQSameUser?this.state.EmailAddress:this.state.NetQEmail}
          handleNetqSameUserChange={this.handleNetqSameUserChange}
          handleNetQFirstNameChange ={this.handleNetQFirstNameChange}
          handleNetQLastNameChange ={this.handleNetQLastNameChange}
          handleNetQEmailChange ={this.handleNetQEmailChange}
          networkOperatingSystem={this.state.NetworkOperatingSystem}
          otherNetworkElements={this.state.OtherNetworkElements}           
          premiseName={this.state.PremiseName}
          numberOfSwicthes={this.state.NumberOfSwicthes}
          handleOtherNetworkElementsChange={this.handleOtherNetworkElementsChange}
          handlePremiseNameChange ={this.handlePremiseNameChange }
          handleNumberOfSwicthesChange={this. handleNumberOfSwicthesChange}
          handleNetworkOperatingSystemChange={this.handleNetworkOperatingSystemChange} 
          handleNetQSaasChange={this.handleNetqSaasChange}
          handleBoxDeployment={this.handleBoxxDeploymentChange}
          handleBoxDeploymentTypeChange={this. handleBoxxDeploymentTypeChange}
          handleBoxxNvidiaGPUChange={this.handleBoxNvidiaGPUChange}            
          handleCertfiiedSystemChange={this. handleBoxxCertifiedServerChange }
          handlePartnerDeployment={this.handlePartnerDeploymentChange}
          handlePartnerCertifiedSystemChange={this.handlePartnerCertifiedSystemChange}
          handlePartnerNvidiaGPUChange={this.handlePartnerNvidiaGPUChange}            
          handleCompatibleHardwareChange={this.handleCompatibleHardwareChange}
          handleVMWareChange={this.handleVMWareChange}
          handleOtherValChange={this.handleOtherApplicationChange}
          handleServerChange={this.handleServerChange}
          handleHypervisorChange={this.handleHypervisorChange}
          handleSeatsChange={this.handleSeatsChange}
          handleGpuChange={this.handleGpuChange}
          handleRemotingChange={this.handleRemotingChange}
          handlePrimaryAppChange={this.handlePrimaryAppChange}
          handleGPUDeployed={this.handleGPUDeployed}
          handleDeployment={this.handleDeploymentOptions}
          handleUseCaseOptions={this.handleUseCase}
          handleEstimatedUsersOptions={this.handleEstimatedUsers}
          
        />
      )}
      {/* check for dgx */}
      {(this.state.IsRWA02 || this.state.IsRWA04) 
      // &&(
      //   <ContainerRegistry
      //     isFleeting={this.state.IsFleetingPF}
      //     adminOptions={this.state.ContainerAdminOptions}
      //     adminOption={this.state.ContainerAdmin}
      //     handleAdminChange={this.handleAdminChange}
      //     isRWA04={this.state.IsRWA04}
      //     lockContainer={!UseAltContainerInfo}
      //     emailAddress={
      //       UseAltContainerInfo
      //         ? this.state.ContainerAltEmail
      //         : this.state.EmailAddress
      //     }
      //     firstName={
      //       UseAltContainerInfo
      //         ? this.state.ContainerAltFirstName
      //         : this.state.FirstName
      //     }
      //     lastName={
      //       UseAltContainerInfo
      //         ? this.state.ContainerAltLastName
      //         : this.state.LastName
      //     }
      //     phone={
      //       UseAltContainerInfo
      //         ? this.state.ContainerAltPhone
      //         : this.state.OrgData.CompanyPhone
      //     }
      //   shortCompanyName={this.state.ContainerShortCompanyName}
      //     handleEmailAddressChange={this.handleContainerEmailChange}
      //     handleFirstNameChange={this.handleContainerFirstNameChange}
      //     handleLastNameChange={this.handleContainerLastNameChange}
      //     handlePhoneChange={this.handleContainerPhoneChange}
      //   handleShortCompanyNameChange={this.handleShortCompanyNameChange}
      //   />
      // )
      }
      <ActionBar
        IsRWA02={this.state.IsRWA02}
        handleSubmitClick={this.handleSubmitClick}
        handleCancelClick={this.handleCancelClick}
        handleSendNewsChange={this.handleSendNewsChange}
        sendNews={this.state.SendNews}
        allowSubmit={this.state.AllowSubmit}
        partnerValue={this.state.PartnerUrlParam}
      />
      </>
      }              
        <Footer />

        {this.state.Messages.length > 0 && (
          <MessageCenter
            messages={this.state.Messages}
            showIcon={this.state.MessageIcon}
            showUserInput={this.state.UserInputNeeded}
            onNoClick={this.handleNoClick}
            onYesClick={this.handleYesClick}
          />
        )}
      </div>
    );
  }
}

//export default ReactAI.withTracking(App);

App.propTypes = {
  licenseType: PropTypes.string,
  productFamily: PropTypes.string,
  pakId: PropTypes.string,
};
